class KeyBinder {
  constructor (keyCallbacks) {
    this.keyCallbacks = keyCallbacks
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  bind () {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  unbind () {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown (event) {
    const callback = this.keyCallbacks[event.key]
    if (callback) {
      callback(event)
    }
  }
}

export default KeyBinder
