<template>
  <v-dialog
    v-model="shouldShowDialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title>
        Brisanje nadcestarije
        <v-spacer />
        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>Jeste li sigurni da želite obrisati nadcestariju <b> {{ roadAdministrationForDelete && !isEmpty(roadAdministrationForDelete) && roadAdministrationForDelete.road_administration_name ? roadAdministrationForDelete.road_administration_name : '' }}</b>?</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="grey darken-1"
          text
          @click="closeDialog"
        >
          Odustani
        </v-btn>

        <v-btn
          class="danger"
          color="red"
          text
          @click="deleteRoadAdministration"
        >
          Obriši
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { api } from '@/global/services/api'

export default {
  name: 'DeleteRoadAdministrationDialog',

  props: {
    openDialog: {
      type: Boolean,
      required: true
    },
    roadAdministrationForDelete: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      shouldShowDialog: false
    }
  },

  watch: {
    openDialog (shouldOpenDialog) {
      this.shouldShowDialog = shouldOpenDialog
    }
  },

  methods: {
    isEmpty,
    closeDialog () {
      this.$emit('close-dialog')
    },

    async deleteRoadAdministration () {
      if (!isEmpty(this.roadAdministrationForDelete) && this.roadAdministrationForDelete.id) {
        await api()['road-maintenance'].delete(`road-administration/${this.roadAdministrationForDelete.id}`)
        this.$emit('update-table')
        this.$emit('close-dialog')
      }
    }
  }
}
</script>
