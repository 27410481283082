<template>
  <v-container
    fluid
    style="position: relative"
  >
    <v-card :disabled="fetchingData">
      <v-data-table
        v-stripped
        class="elevation-0"
        :headers="headers"
        :items="list"
        :server-items-length="pagination.total"
        :items-per-page="pagination.perPage"
        :footer-props="{
        itemsPerPageOptions: [10, 50, 100]
      }"
        :options.sync="options"
        :loading="fetchingData"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
      >
        <!-- Header -->
        <template #top>
          <v-toolbar
            flat
            class="toolbar-container"
          >
            <div class="header-row">
              <v-toolbar-title class="toolbar-title" v-text="viewConfig.title" />
              <v-col>
                <v-btn
                  icon
                  @click="getItems"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-col>

              <v-spacer />

              <v-btn
                v-show="!!viewConfig.create_button"
                :disabled="!canCreate"
                rounded
                color="primary"
                class="white--text px-4 mr-3 pr-5"
                @click.prevent="handleCreateBtnClick"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-plus
                </v-icon>
                {{ viewConfig.create_button }}
              </v-btn>
            </div>
          </v-toolbar>

          <v-divider />
        </template>

        <template
          #[`item.only_patrols`]="{ item }"
        >
          <div>
            <v-tooltip
              v-if="item.only_patrols"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  size="23px"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Vidljivo samo na ophodarima</span>
            </v-tooltip>

            <v-tooltip
              v-else
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  size="23px"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-close-circle
                </v-icon>
              </template>
              <span>Vidljivo na prijekopima i ophodarima</span>
            </v-tooltip>
          </div>
        </template>
        <template
          #[`item.cityDistricts`]="{ item }"
        >
          <div v-if="item.cityDistricts && item.cityDistricts.length">
            <span v-for="(cityDistrict, index) in item.cityDistricts" :key="index">
                {{ cityDistrict.name }}
              <span v-if="index < item.cityDistricts.length - 1">, </span>
            </span>
          </div>
          <div v-else>
            <span>-</span>
          </div>
        </template>
        <!-- Datatable actions -->
        <template
          #[`item.actions`]="{ item }"
        >
          <v-menu
            offset-y
            offset-overflow
            left
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="canSeeActions(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dots-vertical
              </v-icon>
            </template>

            <v-list class="pa-0">
              <v-list-item
                class="d-flex align-center list-item-min-height"
                v-if="canBeEdited(item)"
                link
                @click="openEditDialog(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon small>
                    {{ viewConfig.actions.edit.icon || '' }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ viewConfig.actions.edit.text || '' }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="d-flex align-center list-item-min-height"
                v-if="canBeDeleted(item)"
                link
                @click="openDeleteDialog(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon small>
                    {{ viewConfig.actions.delete.icon || '' }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ viewConfig.actions.delete.text || '' }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <adjust-road-administration-dialog
      :open-dialog="createOrUpdateDialog"
      :city-districts="cityDistricts"
      :dialog-title="dialogTitle"
      :edit-item="editItem"
      @close-dialog="closeAdjustRoadAdministrationDialog"
      @update-table="getItems"
    />
    <delete-road-administration-dialog
      :open-dialog="deleteDialog"
      :road-administration-for-delete="roadAdministrationForDelete"
      @close-dialog="closeDeleteDialog"
      @update-table="getItems"
    />
  </v-container>
</template>

<script>
import { isEmpty } from 'lodash'
import { api } from '@/global/services/api'
import AdjustRoadAdministrationDialog from './AdjustRoadAdministrationDialog.vue'
import DeleteRoadAdministrationDialog from './DeleteRoadAdministrationDialog.vue'

export default {
  components: {
    DeleteRoadAdministrationDialog,
    AdjustRoadAdministrationDialog
  },

  props: {
    module: {
      type: String,
      default: 'base',
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    sort: {
      type: Object,
      default: () => ({})
    },
    pagination: {
      type: Object,
      required: true
    },
    viewConfig: {
      type: Object,
      required: true
    },
    fetch: {
      type: Function,
      required: true
    },
    noActions: {
      type: Boolean,
      default: false
    },
    showEditDialog: {
      type: Boolean,
      default: false
    },
    cityDistricts: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      filters: {},
      columns: {},
      createOrUpdateDialog: false,
      editItem: {},
      deleteDialog: false,
      roadAdministrationForDelete: {},
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['road_administration_name'],
        sortDesc: []
      },
      fetchingData: false,
      itemsInitiallyLoad: false
    }
  },
  computed: {
    canCreate () {
      if ('actions' in this.viewConfig) {
        return 'create' in this.viewConfig.actions
      }

      return false
    },

    canEdit () {
      if ('actions' in this.viewConfig) {
        return 'edit' in this.viewConfig.actions
      }

      return false
    },

    canDelete () {
      if ('actions' in this.viewConfig) {
        return 'delete' in this.viewConfig.actions
      }

      return false
    },

    dialogTitle () {
      let title = ''
      if (!isEmpty(this.editItem)) {
        title = this.viewConfig.edit_form?.title || ''
      }
      else {
        title = this.viewConfig.create_form?.title || ''
      }

      return title
    },

    filteredOptions () {
      return {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0] ? this.options.sortBy[0] : null,
        sortDesc: this.options.sortDesc[0] ? this.options.sortDesc[0] : null
      }
    },

    headers () {
      const data = []
      data.push(
        {
          text: 'Nadcestarija',
          value: 'road_administration_name',
          align: 'center',
          class: 'custom-data-table-header',
          sortable: true
        },
        {
          text: 'Gradske četvrti',
          value: 'cityDistricts',
          align: 'center',
          class: 'custom-data-table-header',
          sortable: false
        },
        {
          text: 'Samo ophodari',
          value: 'only_patrols',
          align: 'center',
          class: 'custom-data-table-header',
          sortable: false
        }
      )

      if (!this.noActions) {
        data.push(
          {
            text: this.$t('base.actions'),
            value: 'actions',
            align: 'center',
            class: 'custom-data-table-header',
            sortable: false
          }
        )
      }

      return data
    }
  },

  watch: {
    pagination: {
      deep: true,
      handler (value) {
        this.options.itemsPerPage = value.perPage
      }
    },

    filteredOptions: {
      deep: true,
      handler () {
        if (this.itemsInitiallyLoad) {
          this.getItems()
        }
      }
    },

    setColumns: {
      immediate: true,
      handler (columns) {
        this.columns = columns
      }
    },

    list () {
      if (!this.options.itemsPerPage) {
        this.options.itemsPerPage = this.pagination.perPage
      }
    },

    showEditDialog (value) {
      this.createOrUpdateDialog = !!value
    }
  },

  async created () {
    this.setPreviouslyVisitedStorePagination()
    await this.getItems()
  },

  methods: {
    setPreviouslyVisitedStorePagination () {
      this.options.page = this.pagination.currentPage
    },

    canSeeActions (item) {
      if ('actions' in item) {
        return item.actions.length
      }

      return this.canEdit || this.canDelete
    },

    canBeEdited (item) {
      if ('actions' in item) {
        return this.canEdit && item.actions.includes('edit')
      }

      return this.canEdit
    },

    canBeDeleted (item) {
      if ('actions' in item) {
        return this.canDelete && item.actions.includes('delete')
      }

      return this.canDelete
    },

    async getItems (resetPage = false) {
      if (resetPage) {
        this.options.page = 1
      }
      const options = this.filteredOptions
      const params = {
        pagination: {
          perPage: options.itemsPerPage,
          currentPage: options.page
        },
        sort: {
          by: options.sortBy,
          desc: options.sortDesc
        }
      }
      try {
        this.fetchingData = true
        await this.fetch(params)
        if (options.itemsPerPage === 10) {
          this.itemsInitiallyLoad = true
        }
      }
      finally {
        this.fetchingData = false
      }
    },

    async openEditDialog (item) {
      const response = await api()[this.module].get((this.viewConfig.routeString || '') + '/' + item.id)
      this.editItem = Object.assign({}, response.data)
      this.createOrUpdateDialog = true
    },

    handleCreateBtnClick () {
      this.createOrUpdateDialog = true
    },

    closeAdjustRoadAdministrationDialog () {
      this.editItem = {}
      this.createOrUpdateDialog = false
    },

    openDeleteDialog (item) {
      this.roadAdministrationForDelete = item
      this.deleteDialog = true
    },

    closeDeleteDialog () {
      this.editItem = {}
      this.roadAdministrationForDelete = {}
      this.deleteDialog = false
    },

    isEmpty (value) {
      return isEmpty(value)
    }
  }
}
</script>

<style scoped lang="scss">
.v-card__title {
  padding: 10px 8px 8px 16px;
  font-size: 1rem;
}

.table-data-preview {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
::v-deep .theme--light.v-tabs > .v-tabs-bar {
  width: 21%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

::v-deep .form-fields-card {
  border: 2px solid var(--v-grey-lighten-1-base) !important;
  .tabs-wrapper {
    border-top: 2px solid var(--v-grey-lighten-1-base);

    .v-window-item {
      min-height: 65vh;

      .form-inputs-card {
        border-radius: 0 !important;
      }
    }

    .v-item-group {
      &:first-of-type {
        border-right: 2px solid var(--v-grey-lighten-1-base) !important;

        .v-slide-group__wrapper {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 36px;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: normal;
  text-transform: none;
}
.v-tab--active {
  background: #E2E2E2;
}
::v-deep .v-tabs-slider-wrapper {
  height: 36px;
  width: 6px !important;
  .v-tabs-slider {
    background-color: #6aac49;
  }
}

.header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.toolbar-container {
  padding: 0 1rem;
}

.toolbar-title {
  color: #869DB6;
}

.map-location {
  cursor: pointer;
}

::v-deep {
  .row-pointer {
    div.v-data-table__wrapper {
      > table > tbody > tr :hover {
        cursor: pointer;
      }
    }
  }
  .v-data-table-header__icon {
    opacity: 1;
  }
}

</style>
