<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    :mapOptions="mapOptions"
  >
    <template #mapContent>
      <create-traffic-lights-modal
        :is-opened="isModalOpen"
        :edit-item="editItem"
        :editing="true"
        :can-edit="canEdit"
        :mapClass="'traffic-lights-edit-location-map-modal'"
        @pull-data="resetIcons"
        @close-modal="closeModal"
      />
    </template>
  </map-base>
</template>

<script>
import L from 'leaflet'
import { createNamespacedHelpers } from 'vuex'
import CreateTrafficLightsModal from './CreateTrafficLightModal.vue'
import { api } from '@/global/services/api'
import MapBase from '@/global/components/map/MapBase.vue'

const { mapActions: mapActionsTracking } = createNamespacedHelpers('satellite-tracking/live-tracking')
const { mapActions: mapActionsTrafficLights, mapGetters: mapGettersTrafficLights } = createNamespacedHelpers('road-maintenance/traffic-lights')

export default {
  name: 'TrafficLightsMap',

  components: {
    CreateTrafficLightsModal,
    MapBase
  },

  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    permissions: {
      type: Array,
      required: false,
      default: () => []
    },
    invokeGetIcons: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isModalOpen: false,
      editItem: {},
      mapStyle: {
        zIndex: 0,
        flex: 1
      },
      mapOptions: {
        renderer: L.canvas()
      }
    }
  },

  computed: {
    ...mapGettersTrafficLights(['mapItems', 'icons']),

    canEdit () {
      return this.permissions[0]?.updateTrafficLights
    }
  },

  watch: {
    invokeGetIcons () {
      this.resetIcons()
      this.$emit('reset-invoke-get-icons')
    },
    async options (newOptions) {
      if (newOptions) {
        await this.getIcons({
          trafficLightType: newOptions.trafficLightType
        })
        this.mapItems?.forEach(item => {
          item.click = () => {
            this.openEditDialog(item.id)
          }
        })
        const markersConfig = {
          markers: this.mapItems,
          icons: this.icons,
          markerClusterOptions: {
            maxClusterRadius: 40
          }
        }
        this.generateMarkers(markersConfig)
      }
    }
  },

  async created () {
    await this.fetch()
  },

  methods: {
    ...mapActionsTracking(['fetch']),
    ...mapActionsTrafficLights(['getIcons']),
    closeModal () {
      this.isModalOpen = false
      this.$emit('close-edit-dialog')
    },
    async openEditDialog (item) {
      const fetched = await api('road-maintenance').get('/traffic-lights/' + item)
      this.editItem = fetched.data
      this.isModalOpen = true
    },

    // Invalidate map size function to be called from parent component so the missing tiles are fetched
    invalidateMapSize () {
      this.$refs?.mapBase?.$refs?.map?.mapObject?.invalidateSize()
    },

    async resetIcons () {
      await this.getIcons({
        trafficLightType: this.options.trafficLightType
      })
      this.mapItems.forEach(item => {
        item.click = () => {
          this.openEditDialog(item.id)
        }
      })

      const markersConfig = {
        markers: this.mapItems,
        icons: this.icons,
        markerClusterOptions: {
          maxClusterRadius: 40
        }
      }

      this.generateMarkers(markersConfig)
    },

    generateMarkers (markersConfig) {
      this.$refs?.mapBase?.onMapReady(function (map) {
        map.generateMarkers(markersConfig)
      })
    }
  }
}
</script>

<style>
/*
  If multiple controls are added into the controls slot, they should be separated with a v-divider.
  This styles the v-divider to maintain consistent spacing.
*/
.leaflet-control .v-divider{
  margin: 8px 0;
}
.v-icon {
  font-size: 1.5rem;
}
.v-label {
  font-size: 0.82rem;
}

.red_traffic_light,
.yellow_traffic_light,
.green_traffic_light,
.white_traffic_light {
  z-index:1000;
  border-radius: 50%;
  box-shadow: 0 0 10px 2px;
}
.red_traffic_light:hover,
.yellow_traffic_light:hover,
.green_traffic_light:hover {
  box-shadow: 0 0 10px 2px orange;
}

.red_traffic_light,
.yellow_traffic_light,
.green_traffic_light,
.white_traffic_light {
  width: 8px;
  height: 18px;
  border: 1px solid #333;
  border-radius: 2px;
  position: relative;
}

.red_traffic_light {
  background-color: rgb(244, 67, 54);
}

.yellow_traffic_light {
  background-color: rgb(255, 235, 59);
}

.green_traffic_light {
  background-color: rgb(76, 175, 80);
}

.white_traffic_light {
  background-color: rgb(255, 255, 255);
}

.red_traffic_light::before,
.yellow_traffic_light::before,
.green_traffic_light::before,
.white_traffic_light::before,
.red_traffic_light::after,
.yellow_traffic_light::after,
.green_traffic_light::after,
.white_traffic_light::after {
  content: "";
  width: 50%;
  height: 30%;
  border-radius: 50%;
  border: 1px solid #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.red_traffic_light::before,
.yellow_traffic_light::before,
.green_traffic_light::before,
.white_traffic_light::before {
  top: 12%;
}

.red_traffic_light::after,
.yellow_traffic_light::after,
.green_traffic_light::after,
.white_traffic_light::after {
  bottom: 12%;
}
</style>
