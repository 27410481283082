<template>
  <index-view
    :module="module"
    :list="list"
    :sort="sort"
    :pagination="pagination"
    :fetch="fetch"
  />
</template>

<script>
import indexView from '../components/PatrolVehicleServiceTable.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/patrol-vehicle')

export default {
  name: 'FleetVehiclesView',

  components: {
    'index-view': indexView
  },

  props: {
    module: {
      type: String,
      default: 'road-maintenance'
    }
  },

  computed: {
    ...mapGetters(['list', 'pagination', 'sort'])
  },

  methods: {
    ...mapActions([
      'fetch'
    ])
  }
}
</script>
