<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    class="map-work-orders-base"
    :map-options="mapOptions"
    :update-center="resetIcon"
    :map-options-picker-config="mapPickerConfig"
    @map-options-updated="mapOptionsUpdated"
    @marker-zoom-updated="zoomLevel => $emit('set-history-switch-visible', zoomLevel > 14)"
  >
    <template #mapContent>
      <v-dialog
        v-model="WorkOrderDialog"
        max-width="900px"
      >
        <show-work-order
          :item="item"
          @close="withPullData => closeWorkOrderDialog(withPullData)"
        />
      </v-dialog>
    </template>
  </map-base>
</template>
<script>
import L from 'leaflet'
import { createNamespacedHelpers } from 'vuex'
import ShowWorkOrder from '@/modules/road-maintenance-module/patrol-service/components/ShowWorkOrder.vue'
import { isEmpty } from 'lodash'
import MapBase from '@/global/components/map/MapBase.vue'
const { mapActions: mapActionsWorkOrder, mapGetters: mapGettersWorkOrder } = createNamespacedHelpers('road-maintenance/work-order')
const { mapActions: mapActionsWorkOrders, mapGetters: mapGettersWorkOrders } = createNamespacedHelpers('road-maintenance/work-orders')

export default {
  name: 'MapActiveWorkOrders',
  components: {
    ShowWorkOrder,
    MapBase
  },
  props: {
    mapOptions: {
      type: Object,
      default: () => ({
        renderer: L.canvas()
      })
    },
    options: {
      type: Object,
      default: () => {}
    },
    selectedIcon: {
      type: Object,
      default: () => ({})
    },
    keyBinderClass: {
      type: Object,
      default: () => ({})
    },
    showHistory: {
      type: Boolean,
      default: false
    },
    invokeGetIcons: {
      type: Boolean,
      default: true
    },
    cityDistricts: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      ShowWorkOrder: false,
      WorkOrderDialog: false,
      markers: {},
      markersClusterGroup: {},
      historyMarkers: [],
      oldSelectedItem: false,
      trafficLightClasses: ['red_traffic_light', 'yellow_traffic_light', 'green_traffic_light', 'white_traffic_light'],
      mapStyle: {
        zIndex: 0,
        flex: 1
      },
      mapPickerConfig: {
        content: [
          {
            title: 'Opcije',
            items: [
              {
                id: 'showCityDistricts',
                label: 'Prikaži gradske četvrti'
              }
            ]
          }
        ]
      }
    }
  },
  emits: ['set-history-switch-visible', 'reset-invoke-get-icons', 'bind-keyBinder-class', 'reset-zoom', 'get-data'],
  computed: {
    ...mapGettersWorkOrder(['item']),
    ...mapGettersWorkOrders(['mapItems', 'icons', 'historyMapItems']),
    active () {
      return this.$route.name === 'ActiveWorkOrders'
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      async handler (newOptions) {
        this.$refs?.mapBase?.startMapLoader()
        if (newOptions && !isEmpty(newOptions) && newOptions.subject_report.length !== 0 && newOptions.hazard_level.length !== 0) {
          await this.getIcons({ active: this.active, from: newOptions.from, to: newOptions.to, subject_report: newOptions.subject_report, hazard_level: newOptions.hazard_level, address: newOptions.address, tipNumber: newOptions.tipNumber })

          this.$emit('reset-invoke-get-icons')
          this.mapItems.forEach(item => {
            item.click = () => {
              this.showWorkOrderDialog(item.id)
            }
          })
          const markersConfig = {
            markers: this.mapItems,
            icons: this.icons,
            markerClusterOptions: {
              maxClusterRadius: 40
            }
          }
          this.generateMarkers(markersConfig)
        }
        this.$refs?.mapBase?.stopMapLoader()
      }
    },
    selectedIcon (item) {
      if (!isEmpty(item)) {
        const marker = this.$refs.mapBase?.findMarkerById(item.id)
        if (this.oldSelectedItem) {
          const marker2 = this.$refs.mapBase?.findMarkerById(this.oldSelectedItem.id)
          var currentClass2 = marker2.getIcon().options.className
          const oldIcon = L.divIcon({
            className: currentClass2.replace(' selected', ''),
            iconSize: this.trafficLightClasses.includes(currentClass2.replace(' selected', '')) ? [10, 18] : [12, 12]
          })
          marker2.setIcon(oldIcon)
        }
        this.$refs?.mapBase?.$refs?.map?.mapObject?.setView(L.latLng([item.latitude, item.longitude]), 16)
        var currentClass = marker.getIcon().options.className
        const newIcon = L.divIcon({
          className: currentClass + ' selected',
          iconSize: this.trafficLightClasses.includes(currentClass) ? [20, 32] : [32, 32]
        })
        marker.setIcon(newIcon)
        this.oldSelectedItem = item
      }
    },
    async showHistory (state) {
      if (state) {
        this.$refs?.mapBase?.stopMapLoader()
        await this.generateHistoryIcons()
        this.$refs?.mapBase?.lockMap()
        this.$refs?.mapBase?.stopMapLoader()
      }
      else {
        this.$refs?.mapBase?.stopMapLoader()
        this.$refs?.mapBase?.unlockMap()
        this.$refs?.mapBase?.removeMarkers()
        await this.generateIcons(false)
        this.$refs?.mapBase?.stopMapLoader()
      }
    },
    async invokeGetIcons (state) {
      if (state) {
        await this.generateIcons()
        this.$emit('reset-invoke-get-icons')
      }
    },
    WorkOrderDialog (val) {
      if (!val) {
        this.$emit('bind-keyBinder-class')
      }
    }
  },
  methods: {
    ...mapActionsWorkOrder(['fetchWorkOrder']),
    ...mapActionsWorkOrders(['getIcons']),
    async generateIcons (fitMarkers = true) {
      this.oldSelectedItem = false
      await this.getIcons({ active: this.active, from: this.options.from, to: this.options.to, subject_report: this.options.subject_report, hazard_level: this.options.hazard_level, address: this.options.address, tipNumber: this.options.tipNumber })
      if (this.mapItems.length) {
        this.mapItems.forEach(item => {
          item.click = () => {
            this.showWorkOrderDialog(item.id)
          }
        })
        const markersConfig = {
          markers: this.mapItems,
          icons: this.icons,
          markerClusterOptions: {
            maxClusterRadius: 40
          },
          fitMarkers: fitMarkers
        }
        this.generateMarkers(markersConfig)
      }
      else {
        this.$refs.mapBase?.removeMarkers()
      }
    },

    async generateHistoryIcons () {
      const bounds = this.$refs?.mapBase?.getCurrentMapBounds()
      const minLat = bounds._southWest.lat
      const minLon = bounds._southWest.lng
      const maxLat = bounds._northEast.lat
      const maxLon = bounds._northEast.lng
      await this.getIcons({
        active: false,
        min_lat: minLat,
        min_lon: minLon,
        max_lat: maxLat,
        max_lon: maxLon,
        from: this.options.from,
        subject_report: this.options.subject_report,
        hazard_level: this.options.hazard_level,
        address: this.options.address,
        tipNumber: this.options.tipNumber
      })
      this.historyMapItems.forEach(item => {
        item.click = () => {
          this.showWorkOrderDialog(item.id)
        }
      })
      const markersConfig = {
        markers: this.historyMapItems,
        icons: this.icons,
        keepExisting: true,
        markerClusterOptions: {
          maxClusterRadius: 40
        },
        fitMarkers: false
      }
      this.generateMarkers(markersConfig)
      this.$emit('reset-invoke-get-icons')
    },
    mapOptionsUpdated (updatedOptions) {
      if (updatedOptions.showCityDistricts) {
        if (this.cityDistricts && this.cityDistricts.length) {
          const polygonConfig = {
            polygons: []
          }
          this.cityDistricts.forEach(cityDistrict => {
            if (cityDistrict && !isEmpty(cityDistrict) && cityDistrict.data) {
              const coordinatesString = cityDistrict.data.match(/\(\(([^)]+)\)\)/)[1]

              const coordinatesPairs = coordinatesString.split(', ')

              const coordinatesArray = coordinatesPairs.map(pair => {
                const [latitude, longitude] = pair.split(' ').map(parseFloat)
                return [latitude, longitude]
              })
              polygonConfig.polygons.push({
                coordinates: coordinatesArray,
                label: cityDistrict.name,
                options: {
                  color: cityDistrict.color,
                  className: 'polygon-label'
                }
              })
              this.$refs?.mapBase?.generatePolygons(polygonConfig)
            }
          })
        }
      }
      else {
        this.$refs?.mapBase?.removeAllPolygons()
      }
    },
    generateMarkers (markersConfig) {
      this.$refs?.mapBase?.onMapReady(function (map) {
        map.generateMarkers(markersConfig)
      })
    },
    async showWorkOrderDialog (id) {
      if (this.keyBinderClass) {
        this.keyBinderClass.unbind()
      }
      await this.fetchWorkOrder(id)
      this.WorkOrderDialog = true
    },
    // Invalidate map size function to be called from parent component so the missing tiles are fetched
    invalidateMapSize () {
      this.$refs?.mapBase?.getMapObject()?.invalidateSize()
    },
    resetIcon (value) {
      if (!isEmpty(this.selectedIcon)) {
        const marker = this.$refs.mapBase?.findMarkerById(this.selectedIcon.id)
        if ((value.lat.toFixed(4) !== this.selectedIcon.latitude.toFixed(4) || value.lng.toFixed(4) !== this.selectedIcon.longitude.toFixed(4)) && marker) {
          const currentClass2 = marker.getIcon().options.className
          const oldIcon = L.divIcon({
            className: currentClass2.replace(' selected', ''),
            iconSize: this.trafficLightClasses.includes(currentClass2.replace(' selected', '')) ? [10, 18] : [12, 12]
          })
          marker.setIcon(oldIcon)
          this.$emit('reset-zoom')
        }
      }
    },
    closeWorkOrderDialog (withPullData) {
      this.WorkOrderDialog = false
      if (withPullData) {
        this.$emit('get-data')
      }
    }
  }
}
</script>

<style scoped>
/*
  If multiple controls are added into the controls slot, they should be separated with a v-divider.
  This styles the v-divider to maintain consistent spacing.
*/
.map-work-orders-base {
  .leaflet-control .v-divider{
    margin: 8px 0;
  }
  .v-icon {
    font-size: 1.5rem;
  }
  .v-label {
    font-size: 0.82rem;
  }
  .red,
  .yellow,
  .green,
  .white,
  .red_traffic_light,
  .yellow_traffic_light,
  .green_traffic_light,
  .white_traffic_light{
    box-shadow: 0px 0px 10px 2px;
  }
  .red:hover,
  .yellow:hover,
  .green:hover,
  .white:hover,
  .red_traffic_light:hover,
  .yellow_traffic_light:hover,
  .green_traffic_light:hover,
  .white_traffic_light:hover,
  .selected {
    box-shadow: 0px 0px 10px 2px orange;
  }
  .circle {
    border-radius: 50%;
  }

  .red_traffic_light,
  .yellow_traffic_light,
  .green_traffic_light,
  .white_traffic_light {
    width: 8px;
    height: 18px;
    border: 1px solid #333;
    border-radius: 2px;
    position: relative;
  }

  .red_traffic_light {
    background-color: rgb(244, 67, 54);
  }

  .yellow_traffic_light {
    background-color: rgb(255, 235, 59);
  }

  .green_traffic_light {
    background-color: rgb(76, 175, 80);
  }

  .white_traffic_light {
    background-color: rgb(255, 255, 255);
  }

  .red_traffic_light::before,
  .yellow_traffic_light::before,
  .green_traffic_light::before,
  .white_traffic_light::before,
  .red_traffic_light::after,
  .yellow_traffic_light::after,
  .green_traffic_light::after,
  .white_traffic_light::after {
    content: "";
    width: 50%;
    height: 30%;
    border-radius: 50%;
    border: 1px solid #000;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .red_traffic_light::before,
  .yellow_traffic_light::before,
  .green_traffic_light::before,
  .white_traffic_light::before {
    top: 12%;
  }

  .red_traffic_light::after,
  .yellow_traffic_light::after,
  .green_traffic_light::after,
  .white_traffic_light::after {
    bottom: 12%;
  }

  .red_triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 14px solid rgb(244, 67, 54);
    -webkit-filter: drop-shadow(3px 3px 3px  #000);
    filter: drop-shadow(3px 3px 3px  #000);
  }

  .red_triangle:hover {
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .red_triangle.selected {
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top: 0;
    box-shadow: none;
    border-bottom: 27px solid rgb(244, 67, 54);
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .yellow_triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 14px solid rgb(255, 235, 59);
    -webkit-filter: drop-shadow(3px 3px 3px  #000);
    filter: drop-shadow(3px 3px 3px  #000);
  }

  .yellow_triangle:hover {
    -webkit-filter: drop-shadow(3px 3px 3px  orange);
    filter: drop-shadow(3px 3px 3px  orange);
  }

  .yellow_triangle.selected {
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top: 0;
    box-shadow: none;
    border-bottom: 27px solid rgb(255, 235, 59);
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .green_triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 14px solid rgb(76, 175, 80);
    -webkit-filter: drop-shadow(3px 3px 3px  #000);
    filter: drop-shadow(3px 3px 3px  #000);
  }

  .green_triangle:hover {
    -webkit-filter: drop-shadow(3px 3px 3px  orange);
    filter: drop-shadow(3px 3px 3px  orange);
  }

  .green_triangle.selected {
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top: 0;
    box-shadow: none;
    border-bottom: 27px solid rgb(76, 175, 80);
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .white_triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 14px solid rgb(255, 255, 255);
    -webkit-filter: drop-shadow(3px 3px 3px  #000);
    filter: drop-shadow(3px 3px 3px  #000);
  }

  .white_triangle:hover {
    -webkit-filter: drop-shadow(3px 3px 3px  orange);
    filter: drop-shadow(3px 3px 3px  orange);
  }

  .white_triangle.selected {
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top: 0;
    box-shadow: none;
    border-bottom: 27px solid rgb(255, 255, 255);
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .polygon-label {
    background-color: blue;
  }
}
</style>
