<template>
  <div>
    <v-row>
      <v-col
        cols="auto"
        md="auto"
        lg="auto"
        xl="auto"
        class="mt-0"
      >
        <v-btn
          class="mt-4"
          plain
          @click="filterDialog = true"
        >
          <v-icon
            large
          >
            mdi-filter-variant
          </v-icon>
          <span class="ml-5">Filteri</span>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        md="auto"
        lg="auto"
        xl="auto"
      >
        <v-btn
          class="mt-4"
          v-show="canCreate"
          @click="isModalOpen = true"
          plain
        >
          <v-icon large>mdi-plus</v-icon>
          <span class="ml-5">Novi semafor</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="filterDialog"
      max-width="300"
      persistent
    >
      <v-card>
        <v-card-title>Statusi</v-card-title>
        <v-card-text
          class="pt-5"
        >
          <v-row
            v-for="trafficLightItem in trafficLightTypes"
            :key="trafficLightItem.value"
            class="ml-1"
          >
              <v-switch
                v-model="selectedTrafficLightTypes"
                class="ma-0"
                :value="trafficLightItem.value"
                :label="trafficLightItem.text"
              />
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end mt-4" style="background-color: #eeeeee;">
          <v-btn
            color="primary"
            primary
            class="mb-1 mt-1"
            @click="filterStatus"
          >
            {{ $t('base.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="filterIsNotSelected"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>Upozorenje</v-card-title>
        <v-card-text>
          Najmanje jedan status mora biti označen!
        </v-card-text>
        <v-card-actions class="justify-end mt-4" style="background-color: #eeeeee;">
          <v-btn
            color="primary"
            class="mr-2"
            @click="filterIsNotSelected = false">
            Zatvori
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <create-traffic-light-modal
      :is-opened="isModalOpen"
      :editing="false"
      :can-create="canCreate"
      :mapClass="'traffic-lights-create-location-map-modal'"
      @pull-data="emitPullData"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import CreateTrafficLightModal from './CreateTrafficLightModal.vue'
import { api } from '@/global/services/api'
export default {
  name: 'TrafficLightsControls',

  components: {
    CreateTrafficLightModal
  },

  props: {
    permissions: {
      type: Array,
      required: false,
      default: () => []
    },
    openEditDialog: {
      type: Boolean,
      default: false
    },
    mapMarkerClicked: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      companyScopeId: null,
      defaultDialogSize: { xl: '55%', lg: '55%', md: '50%', sm: '66.66%', xs: '100%' },
      trafficLightTypes: {},
      filterDialog: false,
      selectedTrafficLightTypes: [],
      isModalOpen: false,
      editItem: {},
      editing: false,
      menuIsOpen: false,
      filterIsNotSelected: false
    }
  },

  computed: {
    canCreate () {
      return this.permissions[0]?.createTrafficLights
    }
  },

  watch: {
    openEditDialog (open) {
      this.isModalOpen = open
      this.editing = open
    }

  },

  created () {
    this.fetchFilters()
  },

  methods: {

    emitPullData () {
      this.$emit('pull-data')
    },

    closeModal () {
      this.isModalOpen = false
    },
    async fetchFilters () {
      const fetched = await api('road-maintenance').get('/traffic-lights/get-filters')
      this.trafficLightTypes = fetched.map(item => {
        return {
          value: item.id,
          text: this.$t(item.description)
        }
      })
      this.selectedTrafficLightTypes = this.trafficLightTypes.map(item => item.value)
      this.emitGetOptions()
    },
    getFiltered () {
      this.emitGetOptions()
    },

    filterStatus () {
      if (this.selectedTrafficLightTypes.length) {
        this.emitGetOptions()
        this.$emit('pull-data')
        this.filterDialog = false
      }
      else {
        this.filterIsNotSelected = true
      }
    },
    emitGetOptions () {
      this.$emit('get-options', {
        trafficLightTypes: this.selectedTrafficLightTypes
      })
    }
  }
}
</script>
