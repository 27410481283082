<template>
  <ShowView
    :module="module"
    :model="model"
    :show-item="showItem"
    :view-config="viewConfig"
    :fetch="fetch"
    :namespace="'road-maintenance/task'"
  />
</template>

<script>
import ShowView from '@/global/components/Show'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/task')
export default {
  name: 'TaskView',

  components: {
    ShowView
  },

  props: {
    module: {
      type: String,
      default: 'road-maintenance'
    },
    model: {
      type: String,
      default: 'task'
    }
  },

  computed: {
    ...mapGetters(['showItem', 'viewConfig'])
  },

  created () {
    const taskId = this.$route.params.id
    this.fetch(taskId)
  },

  async updated () {
  },

  methods: {
    ...mapActions(['fetch'])
  }
}
</script>
