<template>
  <v-dialog
    v-model="canOpenDialog"
    :max-width="600"
    content-class="elevation-0"
    persistent
  >
    <v-card class="form-fields-card">
      <v-card-title>{{ editing ? (canEdit ? 'Uređivanje semafora' : 'Pregled semafora') : 'Novi semafor' }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="4"
            class="mt-0 align-content-center"
          >
            <v-btn
              color="secondary"
              class="mr-2"
              :disabled="editing ? !canEdit : !canCreate"
              @click="openLocationModal"
            >
              {{ editing ? 'Uredi' : 'Odaberi' }} lokaciju
            </v-btn>
          </v-col>
          <v-col
            cols="8"
            class="mt-0"
          >
            <v-text-field
              v-model="formData.location"
              label="Lokacija"
              type="text"
              disabled
              outlined
            />
          </v-col>
        </v-row>
        <v-select
          v-model="formData.trafficLightType"
          label="Vrsta semafora"
          item-title="text"
          item-value="value"
          :disabled="editing ? !canEdit : !canCreate"
          :items="trafficLightTypes"
          outlined
        />
        <v-text-field
          v-model="formData.name"
          label="Naziv"
          type="text"
          :disabled="editing ? !canEdit : !canCreate"
          outlined
        />
        <v-textarea
          v-model="formData.note"
          label="Komentar"
          :disabled="editing ? !canEdit : !canCreate"
          outlined
        />
      </v-card-text>
      <v-card-actions class="justify-end pt-5 pb-5">
        <v-btn
          class="mr-2"
          @click="closeModal"
        >
          Odustani
        </v-btn>
        <v-btn
          v-show="editing ? canEdit : canCreate"
          color="primary"
          class="mr-2"
          :disabled="disableSave"
          @click="saveData"
        >
          Spremi
        </v-btn>
        <v-btn
          v-show="editing ? canEdit : canCreate"
          color="primary"
          class="mr-2"
          :disabled="disableSave"
          @click="saveDataAndCloseModal"
        >
          Spremi i zatvori
        </v-btn>
      </v-card-actions>
    </v-card>

    <geolocation-modal
      v-if="canOpenDialog"
      :geolocation-dialog="showLocationModal"
      :geolocation="editLocation"
      :dialog-title="editing ? 'Uredi lokaciju' : 'Odaberi lokaciju'"
      :map-class="mapClass"
      @close-dialog="showLocationModal = false"
      @set-geolocation="getLocationFromModal"
    />
    <v-dialog
      v-model="openFailedCreatingOrderDialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title>
          {{ $t('road-maintenance.excavation_modal_exception_title') }}
        </v-card-title>
        <v-card-text>
          <template v-if="typeof failedCreatingDialogMessage === 'object'">
            <p
              v-for="(errorMessage, field) in failedCreatingDialogMessage"
              :key="field"
            >
              {{ errorMessage[0] }}
            </p>
          </template>
          <template v-else>
            <p>{{ failedCreatingDialogMessage }}</p>
          </template>
        </v-card-text>
        <v-card-actions
          class="justify-end mt-4"
          style="background-color: #eeeeee;"
        >
          <v-btn
            color="primary"
            class="mr-2"
            @click="openFailedCreatingOrderDialog = false"
          >
            Zatvori
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import GeolocationModal from '@/global/components/modals/GeolocationModal'
const { mapGetters: getters } = createNamespacedHelpers('road-maintenance/traffic-lights')

export default {
  name: 'CreateTrafficLightModal',

  components: {
    GeolocationModal
  },

  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    editItem: {
      type: Object,
      default: () => {}
    },
    editing: {
      type: Boolean,
      default: false
    },
    mapClass: {
      type: String,
      default: 'traffic-lights-location-map-modal'
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canCreate: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showLocationModal: false,
      formData: {
        id: '',
        latitude: '',
        longitude: '',
        location: '',
        name: '',
        note: '',
        trafficLightType: ''
      },
      trafficLightTypes: {},
      editLocation: {},
      canOpenDialog: false,
      exceptionDialog: false,
      validationErrors: {},
      openFailedCreatingOrderDialog: false,
      failedCreatingDialogMessage: '',
      disableSave: false
    }
  },

  computed: {
    ...getters({ mapConfig: 'viewConfig2' })
  },

  watch: {
    isOpened: {
      handler (value) {
        if (value && this.editing && this.editItem) {
          this.formData.location = this.editItem.latitude + ', ' + this.editItem.longitude
          this.formData.name = this.editItem.name
          this.formData.note = this.editItem.note
          this.formData.trafficLightType = this.editItem.traffic_light_type.id
        }
        if (this.formData.trafficLightType === '') {
          this.formData.trafficLightType = this.trafficLightTypes.find(item => item.text === 'Ispravan').value
        }
        if (!value) {
          this.formData.trafficLightType = ''
        }
        this.canOpenDialog = value
      }
    }
  },

  created () {
    this.fetchFilters()
  },

  methods: {

    async fetchFilters () {
      const fetched = await api('road-maintenance').get('/traffic-lights/get-filters')
      this.trafficLightTypes = fetched.map(item => {
        return {
          value: item.id,
          text: this.$t(item.description)
        }
      })
    },

    getLocationFromModal (geolocation) {
      this.formData.location = geolocation.lat + ', ' + geolocation.lng
    },

    closeModal () {
      this.$emit('close-modal')
      this.formData = {}
      this.validationErrors = {}
    },

    async saveData (callbackFunction) {
      const that = this
      const hasCallback = typeof callbackFunction === 'function'
      that.disableSave = true
      try {
        await this.sendData()
        if (hasCallback) {
          callbackFunction(true)
        }
        setTimeout(() => {
          that.disableSave = false
        }, 1000)
      }
      catch (e) {
        if (hasCallback) {
          callbackFunction(false)
        }
        this.validationErrors = e.getValidationErrors()
        this.openFailedCreatingOrderDialog = true
        this.failedCreatingDialogMessage = e.getValidationErrors()
        setTimeout(() => {
          that.disableSave = false
        }, 1000)
      }
    },

    async saveDataAndCloseModal () {
      await this.saveData((success) => {
        if (success) {
          this.$emit('close-modal')
        }
      })
    },

    async sendData () {
      const validated = this.validateFormData()

      if (!validated) {
        return
      }

      if (this.editing) {
        this.prepareDataForUpdate()
        if (this.editItem.id && !isNaN(this.editItem.id)) {
          await api()['road-maintenance'].patch(`traffic-lights/${this.editItem.id}`, this.formData)
        }
      }
      else {
        const [latitude, longitude] = (this.formData.location || '').split(', ')
        await api()['road-maintenance'].post('traffic-lights', {
          name: this.formData.name,
          latitude: latitude,
          longitude: longitude,
          note: this.formData.note,
          trafficLightType: this.formData.trafficLightType
        })
      }

      this.validationErrors = {}
      if (!this.editing) {
        this.formData = {}
      }
      this.$emit('pull-data')
    },

    validateFormData () {
      this.validationErrors = {}
      return true
    },

    prepareDataForUpdate () {
      const [latitude, longitude] = (this.formData.location || '').split(', ')
      this.formData.latitude = latitude
      this.formData.longitude = longitude
    },

    openLocationModal () {
      if (this.formData.location) {
        const [latitude, longitude] = (this.formData.location || '').split(', ')
        this.editLocation = { lat: latitude, lng: longitude }
      }
      else {
        this.editLocation = {}
      }
      this.showLocationModal = true
    }
  }
}
</script>
