<template>
  <v-dialog
    v-model="shouldShowDialog"
    max-width="600"
    persistent
    @keydown.esc.prevent="closeDialog"
    @keydown.enter.prevent="saveRoadAdministration"
  >
    <v-card>
      <v-card-title>
        {{ dialogTitle }}
        <v-spacer />
        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="roadAdministrationForm.road_administration_name"
              :error-messages="errorMessageForName"
              clearable
              :placeholder="'Unesite naziv nadcestarije'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="roadAdministrationForm.city_districts"
              ref="autoComplete"
              :placeholder="'Odabir gradske četvrti'"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="cityDistricts"
              :error-messages="errorMessageCityDistricts"
              item-text="name"
              item-value="id"
              multiple
              @change="handleChangeEvent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="roadAdministrationForm.only_patrols"
            >
              <template v-slot:label>
                <v-tooltip color="black" top>
                  <template v-slot:activator="{ on, attrs }">
                    <span>Samo ophodari</span>
                    <v-icon
                      color="grey darken-1"
                      size="20"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <div style="max-width: 250px; word-break: break-word;">
                    Označavanjem ovog polja, kreirana nadcestarija će biti vidljiva samo na ophodarima, u protivnom će biti vidljiva na prijekopima i na ophodarima.
                  </div>
                </v-tooltip>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="grey darken-1"
          text
          @click="closeDialog"
        >
          Odustani
        </v-btn>

        <v-btn
          class="primary"
          text
          @click="saveRoadAdministration"
        >
          Spremi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { api } from '@/global/services/api'
export default {
  name: 'AdjustRoadAdministrationDialog',

  props: {
    dialogTitle: {
      type: String,
      required: true
    },
    openDialog: {
      type: Boolean,
      required: true
    },
    cityDistricts: {
      type: Array,
      required: true
    },
    editItem: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      roadAdministrationForm: {
        road_administration_name: null,
        city_districts: [],
        only_patrols: false
      },
      shouldShowDialog: false,
      rules: {
        required: value => !!value || 'Naziv nadcestarije je obavezan'
      },
      errorMessageForName: null,
      errorMessageCityDistricts: null
    }
  },

  watch: {
    openDialog (shouldOpenDialog) {
      this.shouldShowDialog = shouldOpenDialog
    },
    editItem (item) {
      if (item && !isEmpty(item)) {
        if (item.road_administration_name && item.road_administration_name !== '') {
          this.roadAdministrationForm.road_administration_name = item.road_administration_name
        }
        if (item.cityDistricts && item.cityDistricts.length) {
          this.roadAdministrationForm.city_districts = item.cityDistricts.map(cityDistrict => {
            return cityDistrict.id
          })
        }
        this.roadAdministrationForm.only_patrols = item.only_patrols ? item.only_patrols : false
      }
    },
    'roadAdministrationForm.road_administration_name' (val) {
      this.errorMessageForName = val === '' ? 'Naziv nadcestarije je obavezan' : null
    }
  },

  methods: {
    closeDialog () {
      this.roadAdministrationForm = {
        road_administration_name: null,
        city_districts: [],
        only_patrols: false
      }
      this.$emit('close-dialog')
      this.errorMessageForName = null
      this.errorMessageCityDistricts = null
    },

    handleChangeEvent (index) {
      this.$refs.autoComplete.setMenuIndex(index)
    },
    async saveRoadAdministration () {
      try {
        // Do create/update only if name of road administration is provided
        if (this.roadAdministrationForm.road_administration_name && this.roadAdministrationForm.road_administration_name !== '') {
          // Update existing road administration
          if (!isEmpty(this.editItem) && this.editItem.id) {
            await api()['road-maintenance'].patch(`road-administration/${this.editItem.id}`, this.roadAdministrationForm)

            this.roadAdministrationForm = {
              road_administration_name: null,
              city_districts: [],
              only_patrols: false
            }
            this.$emit('update-table')
            this.$emit('close-dialog')
            this.errorMessageForName = null
            this.errorMessageCityDistricts = null
          }
          // Create new road administration
          else {
            const formData = new FormData()
            formData.append('road_administration_name', this.roadAdministrationForm.road_administration_name)
            if (this.roadAdministrationForm.city_districts.length) {
              this.roadAdministrationForm.city_districts.forEach(cityDistrict => {
                formData.append('city_districts[]', cityDistrict)
              })
            }
            formData.append('only_patrols', this.roadAdministrationForm.only_patrols)

            await api()['road-maintenance'].post('road-administration', formData)

            // Clearing form inputs
            this.roadAdministrationForm = {
              road_administration_name: null,
              city_districts: [],
              only_patrols: false
            }
            // Emit this event to refresh data table
            this.$emit('update-table')
            // Close current dialog
            this.$emit('close-dialog')
            this.errorMessageForName = null
            this.errorMessageCityDistricts = null
          }
        }
        else {
          this.errorMessageForName = 'Naziv nadcestarije je obavezan'
        }
      }
      catch (e) {
        console.log(e)
        if (e?.response?.data?.errors?.road_administration_name &&
          e?.response?.data?.errors?.road_administration_name.length &&
          e?.response?.data?.errors?.road_administration_name[0] &&
          typeof e?.response?.data?.errors?.road_administration_name[0] === 'string' &&
          e?.response?.data?.errors?.road_administration_name[0].includes('road administration name') &&
          e?.response?.data?.errors?.road_administration_name[0].includes('zauzeta')) {
          this.errorMessageForName = 'Naziv nadcestarije je već zauzet, molimo odaberite drugi naziv'
        }
        else if (e?.response?.data &&
          typeof e?.response?.data === 'string' &&
          e?.response?.data?.includes('city districts') &&
          e?.response?.data?.includes('zauzeta')) {
          this.errorMessageCityDistricts = 'Odabrane lokacije su već zauzete, molimo odaberite druge lokacije'
        }
      }
    }
  }
}
</script>
