<template>
  <v-container
    fluid
    class="ma-0 show-page"
  >
    <v-card
      class="px-5 py-3"
    >
      <div class="px-5 pt-4">
        <v-row class="flex flex-row-reverse">
          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="closeDialog"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <v-row
          class="ma-0 mt-3"
          style="justify-content:center;"
        >
          <p class="ma-0 title text-center">
            {{ item.patrol_order_type.name == 'NOTIFICATION' ?
              $t('road-maintenance/patrol-service.notification_number') :
              $t('road-maintenance/patrol-service.record_number') }} {{ item.id }}
          </p>
        </v-row>
        <v-row class="ma-0 mt-2">
          <v-col
            class="col-lg-12 details-wrapper"
            :class="{'px-0': $vuetify.breakpoint.xs}"
          >
            <div class="item-tables-container">
              <div
                :class="{'px-0' : $vuetify.breakpoint.xs}"
              >
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>{{ $t('road-maintenance/patrol-service.date_and_time') }}:</th>
                      <td>{{ formatDate(item.created_at) }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('road-maintenance/dropdown-types.subject_report') }}:</th>
                      <td>{{ item && item.subject_report && item.subject_report.road_administration_name ? item.subject_report.road_administration_name : '-' }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('road-maintenance/dropdown-types.category') }}:</th>
                      <td>{{ item && item.category && item.category.description ? $t(item.category.description) : '' }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('road-maintenance/dropdown-types.hazard_level') }}:</th>
                      <td>{{ $t(item.hazard_level.description) }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('road-maintenance/patrol-service.address') }}:</th>
                      <td>{{ item.address }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('road-maintenance/patrol-service.description') }}:</th>
                      <td>{{ item.description }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('road-maintenance/patrol-service.created_by') }}:</th>
                      <td>{{ item.created_by }}</td>
                    </tr>
                    <tr v-show="item.notification_images_count">
                      <th>{{ $t('road-maintenance/patrol-service.photos') }}:</th>
                      <td>
                        <div class="image-button-container">
                          <v-btn
                            class="mb-auto transparent-btn"
                            style="background-color: transparent"
                            @click="showGalleryDialog(item.notification_images)"
                          >
                            <img
                              src="/img/icons/images-album.png"
                              alt="Upload-img"
                              width="30"
                              height="30"
                            >
                          </v-btn>
                          <div class="image-count-btn">
                            {{ item.notification_images_count }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-show="item.audio">
                      <th>{{ $t('road-maintenance/patrol-service.audio') }}:</th>
                      <td>
                        <audio
                          controls
                          :src="item.audio"
                          class="mt-3 mb-3"
                          style="width:100%;"
                        >
                          <a :href="item.audio"> {{ $t('road-maintenance/patrol-service.download_audio') }} </a>
                        </audio>
                      </td>
                    </tr>
                    <tr v-show="item.completed_at">
                      <th>{{ $t('road-maintenance/patrol-service.completed_at') }}:</th>
                      <td>{{ formatDate(item.completed_at) }}</td>
                    </tr>
                    <tr v-show="item.completed_by">
                      <th>{{ $t('road-maintenance/patrol-service.completed_by') }}:</th>
                      <td>{{ item.completed_by }}</td>
                    </tr>
                    <tr v-show="item.record_images_count">
                      <th>{{ $t('road-maintenance/patrol-service.photos') }}:</th>
                      <td>
                        <div class="image-button-container">
                          <v-btn
                            class="mb-auto transparent-btn"
                            style="background-color: transparent"
                            @click="showGalleryDialog(item.record_images)"
                          >
                            <img
                              src="/img/icons/images-album.png"
                              alt="Upload-img"
                              width="30"
                              height="30"
                            >
                          </v-btn>
                          <div class="image-count-btn">
                            {{ item.record_images_count }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0 flex flex-row-reverse mt-4">
          <v-btn
            v-if="active"
            color="primary"
            primary
            class="px-7 ml-5 mt-6"
            @click="completeDialog = true"
          >
            Zatvori nalog
          </v-btn>
          <v-btn
            color="primary"
            primary
            class="px-7 mt-6"
            @click="handlePdfExportEvent"
          >
            {{ $t('road-maintenance/patrol-service.print_order') }}
          </v-btn>
        </v-row>
      </div>
    </v-card>
    <v-dialog
      v-model="noteDialog"
      width="40%"
    >
      <v-card>
        <v-card-text>
          <v-textarea
            v-model="note"
            class="pt-6"
            maxlength="255"
            autofocus
            label="Unesite zabilješku"
            auto-grow
            variant="outlined"
            rows="3"
            row-height="25"
          />
        </v-card-text>
        <v-card-actions
          class="flex flex-row-reverse"
        >
          <v-btn
            color="primary"
            primary
            class="px-7 ml-5 mb-3"
            @click="closeNoteDialog"
          >
            {{ $t('base.close') }}
          </v-btn>
          <v-btn
            color="secondary"
            primary
            class="px-7 mb-3"
            @click="selectionReport(item.id, note)"
          >
            {{ $t('road-maintenance/patrol-service.print_order') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="galleryDialog"
      :width="selectedImages.length * 500"
    >
      <image-gallery
        class="mt-6"
        :images="selectedImages"
        @close-image-gallery="closeGalleryDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="completeDialog"
      persistent
      max-width="500"
    >
      <v-card
      >
        <v-row class="flex justify-space-between ma-0 px-2">
          <v-card-title>Upozorenje</v-card-title>
          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="completeDialog = false"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <v-card-text>Da li želite zatvoriti radni nalog?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="completeDialog = false"
          >
            Ne
          </v-btn>
          <v-btn
            color="secondary"
            @click="completeWorkOrder"
          >
            Da
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

import { api } from '@/global/services/api'
import { formatSqlDateTime } from '@/global/services/helpers/dates'
import ImageGallery from '@/modules/road-maintenance-module/patrol-service/components/ImageGallery.vue'

export default {
  name: 'ShowWorkOrder',

  components: {
    ImageGallery
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      galleryDialog: false,
      completeDialog: false,
      noteDialog: false,
      selectedImages: [],
      note: this.item.note
    }
  },

  computed: {
    active () {
      return this.$route.name === 'ActiveWorkOrders'
    }
  },

  watch: {
    item () {
      this.note = this.item.note
    }
  },

  methods: {
    formatDate (date) {
      return formatSqlDateTime(date, 'DD.MM.YYYY HH:mm')
    },
    closeDialog (event, withPullData = false) {
      this.$emit('close', withPullData)
    },
    closeGalleryDialog () {
      this.galleryDialog = false
    },
    closeNoteDialog () {
      this.noteDialog = false
    },
    showGalleryDialog (val) {
      this.selectedImages = val
      this.galleryDialog = true
    },
    openNoteDialog () {
      this.note = this.note ?? this.item.note
      this.noteDialog = true
    },
    handlePdfExportEvent () {
      this.active ? this.openNoteDialog() : this.selectionReport(this.item.id, this.note)
    },
    async selectionReport (id, note) {
      const url = '/api/road-maintenance/work-order-pdf'
      return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          note: note,
          patrol_order_id: id
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (!res.ok) {
            const responseStatusText = res.statusText
            const errorMessage = `${responseStatusText}`
            throw new Error(errorMessage)
          }

          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = window.document.createElement('a')
          const title = 'Nalog_za_sanaciju_nepravilnosti_' + id + '.pdf'
          link.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: 'application/pdf' })
          )
          link.download = title
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.noteDialog = false
        })
        .catch(error => {
          throw new Error('Error occurred:' + error)
        })
    },
    async completeWorkOrder () {
      await api('road-maintenance').post('patrol-vehicle/close-work-order/' + this.item.id)
      this.completeDialog = false
      this.closeDialog(null, true)
    }
  }
}
</script>
<style>
.transparent-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  box-shadow: none;
  text-align: left;
}

.image-button-container {
  position: relative;
  display: inline-block;
}

.image-count-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff5722;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
