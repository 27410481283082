<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    persistent
  >
      <v-card class="modal-card">
        <v-card-title class="modal-header headline grey lighten-2">
          {{$t('base/patrol-vehicle.tips_form_location_modal_title')}}
          <v-spacer />
          <v-btn
            fab
            x-small
            class="pa-0 dialog-close-button no-background-hover"
            style="background: transparent;"
            elevation="0"
            @click="closeDialog"
          >
            <v-icon
              color="grey darken-2"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="modal-content" style="margin: 0; padding: 0; border-top: 2px solid lightslategray; border-bottom: 2px solid lightslategray;">
                  <v-lazy style="height: 100%; padding: 0; margin: 0;">
                    <div style="height: 730px; padding: 0; margin: 0;">
                      <map-base
                        ref="mapBase"
                        :style="mapStyle"
                        :invalidateMapSize="invalidateMapSize"
                        :mapClass="mapClass"
                        @handleClickEvent="handleMapClick"
                      />
                    </div>
                  </v-lazy>
        </v-card-text>
        <v-card-actions class="modal-footer headline grey lighten-2">
          <v-spacer />
          <v-btn
            color="grey"
            dark
            @click="closeDialog"
          >
            {{ $t('base.close') }}
          </v-btn>
          <v-btn
            color="blue-grey darken-1"
            dark
            @click="confirmLocation"
          >
            {{ $t('base/patrol-vehicle.tips_form_location_modal_confirm_button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
import KeyBinder from '../../../../global/services/helpers/KeyBinder'
import MapBase from '@/global/components/map/MapBase.vue'
export default {
  name: 'LocationMapModal',

  components: {
    MapBase
  },

  props: {
    editAddress: {
      type: Object,
      default: () => {}
    },
    locationDialog: {
      type: Boolean,
      required: true
    },
    mapClass: {
      type: String,
      default: 'excavation-location-map-modal'
    }
  },

  data () {
    return {
      dialog: false,
      marker: [],
      mapStyle: {
        height: '100%'
      },
      invalidateMapSize: false
    }
  },

  emits: ['clicked-address', 'close-dialog'],

  watch: {
    locationDialog: function (locationDialog) {
      this.invalidateMapSize = locationDialog
      this.dialog = locationDialog
    },
    editAddress: {
      handler (address) {
        if (address && address.y && address.x) {
          const that = this
          this.setLatLngForMarker(address.y, address.x)
          setTimeout(() => {
            const markersConfig = {
              markers: that.marker,
              fitBoundsOptions: {
                maxZoom: 16
              }
            }
            this.generateMarkers(markersConfig)
          }, 50)
        }
      }
    }
  },

  async created () {
    this.keyCallbacks = {
      Escape: this.handleKeyDownEscape
    }
    this.keyBinder = new KeyBinder(this.keyCallbacks)
    this.keyBinder.bind()
  },

  destroyed () {
    if (this.keyBinder) {
      this.keyBinder.unbind()
    }
  },

  methods: {
    handleKeyDownEscape () {
      if (this.dialog) {
        this.dialog = false
        this.$emit('close-dialog')
      }
    },

    onMarkerDrag (e) {
      this.setLatLngForMarker(e.target._latlng.lat, e.target._latlng.lng)
    },

    handleMapClick (event) {
      const clickedLatLng = event.latlng
      this.setLatLngForMarker(clickedLatLng.lat, clickedLatLng.lng)
      const markersConfig = {
        markers: this.marker,
        fitBoundsOptions: {
          maxZoom: 16
        },
        fitMarkers: false
      }
      this.generateMarkers(markersConfig)
    },

    generateMarkers (markersConfig) {
      this.$refs?.mapBase?.onMapReady(function (map) {
        map.generateMarkers(markersConfig)
      })
    },

    setLatLngForMarker (lat, lng) {
      this.marker = [{
        id: 1,
        lat: lat,
        lon: lng,
        draggable: true,
        drag: (e) => {
          this.onMarkerDrag(e)
        }
      }]
    },

    closeDialog () {
      this.dialog = false
      this.$emit('close-dialog')
      this.$refs?.mapBase?.setCenteringPositions()
      this.$refs?.mapBase?.removeMarkers()
      this.marker = []
    },

    confirmLocation () {
      this.dialog = false
      this.$emit('clicked-address', this.marker)
      this.$emit('close-dialog')
      this.$refs?.mapBase?.setCenteringPositions()
      this.$refs?.mapBase?.removeMarkers()
      this.marker = []
    }
  }
}
</script>

<style scoped>
.modal-card {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
}

.modal-header {
  background-color: transparent;
  padding: 16px;
}

.modal-content {
  flex: 1;
  overflow: hidden;
}

.modal-footer {
  background-color: transparent;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}
</style>
