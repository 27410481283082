<template>
  <div>
    <v-dialog
      v-model="FilterDialog"
      :max-width="isTableVisible ? '90%' : '50%'"
      scrollable
      persistent
    >
      <v-card
        class="d-flex flex-column"
        :height="isTableVisible ? '90vh' : '25vh'"
      >
        <v-card-text>
          <v-row class="ma-0">
            <v-col>
              <v-text-field
                v-model="street"
                label="Ulica"
                clearable
                autofocus
                @click:clear="resetList"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col>
              <v-data-table
                v-show="isTableVisible"
                v-stripped
                class="fill-height overflow-y-auto"
                :headers="header"
                item-key="id"
                :items="list"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.perPage"
                :sort-by.sync="options.sortBy"
                :sort-desc.sync="options.sortDesc"
                :footer-props="{
                  itemsPerPageOptions: [5, 10]
                }"
                @update:options="optionsUpdated"
              >
                <template v-slot:[`item.show_on_map`]="{ item }">
                  <v-btn
                    plain
                    @click="showOnMap(item)"
                  >
                    <v-icon
                      large
                    >
                      mdi-map-marker-radius
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-row class="ma-0 flex flex-row-reverse mb-3">
            <v-btn
              color="primary"
              primary
              class="px-7 ml-5"
              @click="showTable"
            >
              Pretraži
            </v-btn>
            <v-btn
              color="primary"
              primary
              class="px-7"
              @click="closeDialog"
            >
              {{ $t('base.close') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="warningDialog"
      content-class="elevation-0"
      width="20%"
      persistent
      @click:outside="closeWarningDialog"
    >
      <v-card>
        <v-row class="flex justify-space-between ma-0">
          <v-card-title></v-card-title>
          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="closeWarningDialog"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>

        <v-card-text
          class="text-center"
        >
          Unesite naziv ulice!
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue"
            text
            @click="closeWarningDialog"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { forEach } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import KeyBinder from '@/global/services/helpers/KeyBinder'
import { api } from '@/global/services/api'

const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/streets')

export default {
  name: 'StreetSearch',

  props: {
    openDialog: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      street: '',
      FilterDialog: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true]
      },
      isTableVisible: false,
      warningDialog: false
    }
  },

  computed: {
    ...mapGetters(['list', 'pagination']),
    header () {
      const data = []
      const columns = ['street_name', 'name', 'winter_service_base', 'street_district', 'winter_service_team', 'show_on_map']
      const translatedColumns = [
        'Naziv ulice',
        'Naziv zadatka',
        'Baza zimske službe',
        'Gradska četvrt',
        'Ekipa zimske službe',
        'Prikaži na karti'
      ]
      forEach(columns, function (column, key) {
        data.push({
          text: translatedColumns[key],
          value: column,
          align: 'center',
          class: 'data-table-header'
        })
      })
      return data
    }
  },

  watch: {
    street: {
      handler (val) {
        if (val === '') {
          this.resetList()
        }
      }
    },
    openDialog: {
      handler (val) {
        this.FilterDialog = val
      }
    }
  },

  created () {
    this.keyCallbacks = {
      Enter: this.handleKeyDownEnter,
      Escape: this.handleKeyDownEscape
    }
    this.keyBinder = new KeyBinder(this.keyCallbacks)
    this.keyBinder.bind()
  },

  destroyed () {
    this.keyBinder.unbind()
  },

  methods: {
    ...mapActions(['fetch', 'setEmptyItems']),
    getItems () {
      const params = {
        pagination: {
          perPage: this.options.itemsPerPage,
          currentPage: this.options.page
        },
        sort: {
          by: this.options.sortBy[0] ? this.options.sortBy[0] : null,
          desc: this.options.sortDesc[0] ? this.options.sortDesc[0] : null
        },
        street: this.street
      }
      this.fetch(params)
    },
    resetList () {
      this.street = ''
      this.options.page = 1
      this.setEmptyItems()
    },
    showTable () {
      if (!this.street) {
        this.warningDialog = true
        return
      }
      if (!this.isTableVisible) {
        this.isTableVisible = true
      }
      this.getItems()
    },
    optionsUpdated () {
      if (this.list.length) {
        this.getItems()
      }
    },
    closeDialog () {
      this.$emit('dialog-closed')
      this.isTableVisible = false
      this.street = ''
      this.resetList()
      this.FilterDialog = false
    },

    async showOnMap (item) {
      if (item.id) {
        var params = {
          street_id: item.id
        }
        var response = await api('road-maintenance').get('get-street-details', params)
        if (response && response.data && response.data.length) {
          this.closeDialog()
          this.$emit('show-search-on-map', response.data)
        }
      }
    },

    handleKeyDownEnter () {
      if (this.warningDialog) {
        this.closeWarningDialog()
      }
      else if (this.FilterDialog) {
        if (this.street !== '') {
          this.showTable()
        }
        else {
          this.warningDialog = true
        }
      }
    },

    handleKeyDownEscape () {
      if (this.warningDialog) {
        this.closeWarningDialog()
      }
      else if (this.FilterDialog) {
        this.closeDialog()
      }
    },

    closeWarningDialog () {
      this.warningDialog = false
    }
  }
}
</script>

<style scoped>

</style>
