<template>
  <div>
    <v-row @keydown.enter.prevent="closeDatetimePicker">
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="FilterDialog = true && !history"
        >
          <v-icon
            large
          >
            mdi-filter-variant
          </v-icon>
          <span class="ml-5">Filteri</span>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-menu
          v-model="fromMenu"
          transition="slide-y-transition"
          nudge-top="450"
          offset-y
          min-width="auto"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromFormatted"
              :disabled="history === 'true'"
              :label="$t('base.from')"
              prepend-inner-icon="mdi-calendar-start"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="parseDmyDate(fromFormatted)"
            />
          </template>
          <datetime-picker
            v-model="from"
            :emit-default-time="true"
            :default-time="'00:00:00'"
            :min-date="minFromDate"
            :max-date="this.to"
            :reset-tabs="resetFromTabs"
            @close-picker="closeDatetimePicker"
            :on-double-click="closeDatetimePicker"
          >
            <v-col class="white text-right pb-2 px-2">
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closeDatetimePicker"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-col>
          </datetime-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-menu
          v-model="toMenu"
          transition="slide-y-transition"
          nudge-top="450"
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="toFormatted"
              :label="$t('base.to')"
              :disabled="history === 'true'"
              prepend-inner-icon="mdi-calendar-end"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="parseDmyDate(toFormatted)"
            />
          </template>
          <datetime-picker
            v-model="to"
            :emit-default-time="true"
            :default-time="defaultToTime"
            :min-date="minDate"
            :reset-tabs="resetToTabs"
            @close-picker="closeDatetimePicker"
            :on-double-click="closeDatetimePicker"
          >
            <v-col class="white text-right pb-2 px-2">
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closeDatetimePicker"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-col>
          </datetime-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="getFiltered"
        >
          <v-icon
            large
          >
            mdi-file-table
          </v-icon>
          <span class="ml-5">{{ $t('road-maintenance/patrol-service.show_order_list') }}</span>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="exportData"
        >
          <v-icon
            large
          >
            mdi-application-export
          </v-icon>
          <span class="ml-5">{{ $t('road-maintenance/excavation-service.export') }}</span>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-switch
          v-model="history"
          v-show="historySwitchVisible && !active"
          :label="$t('road-maintenance/patrol-service.show_history').toUpperCase()"
          color="green"
          value="true"
          hide-details
        ></v-switch>
      </v-col>
    </v-row>
    <v-dialog
      v-model="FilterDialog"
      max-width="1024px"
      persistent
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="tipNumber"
                  label="Broj dojave"
                  clearable
                  autofocus
                />
                <v-text-field
                  v-model="address"
                  label="Adresa"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <h3>Subjekti dojave</h3>
                <v-switch
                  v-model="selectedSubjectReport"
                  v-for="subjectReport in filters.subject_report"
                  :key="subjectReport.value"
                  color="primary"
                  :value="subjectReport.value"
                  :label="subjectReport.text"
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <h3>Razina opasnosti</h3>
                <v-switch
                  v-model="selectedHazardLevel"
                  v-for="hazardLevel in filters.hazard_level"
                  :key="hazardLevel.value"
                  color="primary"
                  :value="hazardLevel.value"
                  :label="$t(hazardLevel.text)"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="ma-0 flex flex-row-reverse mt-4">
              <v-btn
                color="primary"
                primary
                class="px-7 ml-5 mt-6"
                @click="closeFilterDialog"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay
      :value="exportLoading"
      z-index="1000"
    >
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </div>
</template>

<script>
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import { formatSqlDateTime, parseDmyDate } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'
import { api } from '@/global/services/api'
import KeyBinder from '@/global/services/helpers/KeyBinder'

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default {
  name: 'WorkOrderControls',

  components: {
    DatetimePicker
  },

  props: {
    historySwitchVisible: {
      type: Boolean,
      default: false
    },
    resetHistory: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      today: new Date().toISOString().slice(0, 10),
      from: null,
      fromFormatted: null,
      to: null,
      toFormatted: null,
      companyScopeId: null,
      fromMenu: false,
      toMenu: false,
      history: false,
      resetFromTabs: false,
      resetToTabs: false,
      defaultDialogSize: { xl: '55%', lg: '55%', md: '50%', sm: '66.66%', xs: '100%' },
      filters: {},
      address: null,
      tipNumber: null,
      FilterDialog: false,
      selectedHazardLevel: [],
      selectedSubjectReport: [],
      exportLoading: false
    }
  },

  computed: {
    active () {
      return this.$route.name === 'ActiveWorkOrders'
    },

    defaultToTime () {
      return '23:59:59'
    },

    minFromDate () {
      return dayjs(this.to).subtract(1, 'year').toISOString()
    },

    minDate () {
      return dayjs(this.from).format('YYYY-MM-DD')
    },

    maxToDate () {
      if (this.from) {
        const maxDate = dayjs(this.from).add(31, 'day')
        return maxDate.isAfter(this.today) ? this.today : maxDate.toISOString()
      }
      return this.today
    },

    maxToTime () {
      if (dayjs(this.to).isToday()) return dayjs().format('HH:mm')
      return this.defaultToTime
    }
  },

  watch: {
    from (from) {
      this.fromFormatted = formatSqlDateTime(from, 'DD.MM.YYYY HH:mm')
    },

    to (to) {
      this.toFormatted = formatSqlDateTime(to, 'DD.MM.YYYY HH:mm')
    },

    fromMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetFromTabs = !this.resetFromTabs
    },

    toMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetToTabs = !this.resetToTabs
    },

    history (value) {
      this.$emit('show-history', value)
    },
    historySwitchVisible (value) {
      if (!value) {
        this.history = false
      }
    },
    resetHistory (value) {
      if (value) {
        this.history = false
        this.$emit('reset-history-trigger')
      }
    }
  },

  created () {
    this.from = dayjs().subtract(3, 'months').toISOString()
    this.to = dayjs().toISOString()
    this.fetchFilters()
    this.emitOptions()
    this.keyCallbacks = {
      f: this.handleKeyDownF,
      F: this.handleKeyDownF,
      Enter: this.handleKeyDownEnter,
      Escape: this.handleKeyDownEscape
    }
    this.keyBinder = new KeyBinder(this.keyCallbacks)
    this.keyBinder.bind()
    this.$emit('save-bind-class', this.keyBinder)
  },

  destroyed () {
    if (this.keyBinder) {
      this.keyBinder.unbind()
    }
  },

  methods: {
    handleKeyDownF () {
      if (!this.isModalOpen && !this.FilterDialog) {
        this.FilterDialog = true
      }
    },
    handleKeyDownEscape () {
      if (this.FilterDialog) {
        this.FilterDialog = false
      }
    },
    handleKeyDownEnter () {
      if (!this.isModalOpen && this.FilterDialog) {
        this.closeFilterDialog()
        this.$emit('show-table')
      }
    },
    closeDatetimePicker () {
      this.emitOptions()
      if (this.fromMenu) {
        this.fromMenu = false
      }
      if (this.toMenu) {
        this.toMenu = false
      }
    },

    parseDmyDate (date) {
      return parseDmyDate(date)
    },
    async fetchFilters () {
      const fetched = await api('road-maintenance').get('/work-orders-filters')
      this.filters.subject_report = fetched.subject_report.map(key => {
        this.selectedSubjectReport.push(key.id)
        return {
          value: key.id,
          text: key.road_administration_name
        }
      })
      this.filters.hazard_level = fetched.hazard_level.map(key => {
        this.selectedHazardLevel.push(key.id)
        return {
          value: key.id,
          text: key.description
        }
      })
    },
    getFiltered () {
      if (!this.history) {
        this.emitOptions()
        this.history = false
        this.$emit('reset-history-trigger')
        this.$emit('show-table')
      }
    },
    emitOptions () {
      this.$emit('get-options', {
        from: formatSqlDateTime(this.from, 'YYYY-MM-DD HH:mm:ss'),
        to: formatSqlDateTime(this.to, 'YYYY-MM-DD HH:mm:ss'),
        selectedHazardLevel: this.selectedHazardLevel,
        selectedSubjectReport: this.selectedSubjectReport,
        address: this.address,
        tipNumber: this.tipNumber
      })
    },
    closeFilterDialog () {
      if (this.selectedSubjectReport.length && this.selectedHazardLevel.length) {
        this.emitOptions()
        this.FilterDialog = false
      }
      else {
        alert(this.$t('road-maintenance/patrol-service.filter_alert'))
      }
    },
    async exportData () {
      const columns = [
        'id',
        'created_at',
        'created_by',
        'subject_report',
        'category',
        'hazard_level',
        'address',
        'description'
      ]
      if (!this.active) {
        columns.push('completed_at', 'completed_by')
      }
      const params = {
        columns: columns,
        filters: {
          active: this.active,
          hazard_level: this.selectedHazardLevel,
          from: formatSqlDateTime(this.from, 'YYYY-MM-DD HH:mm:ss'),
          to: formatSqlDateTime(this.to, 'YYYY-MM-DD HH:mm:ss'),
          subject_report: this.selectedSubjectReport,
          address: this.address,
          tipNumber: this.tipNumber
        }
      }
      this.exportLoading = true
      const url = new URL(`${window.location.origin}/api/road-maintenance/patrol-order-export`)
      params.columns.forEach(column => url.searchParams.append('columns[]', column))
      url.searchParams.append('filters', JSON.stringify(params.filters))
      let objectURL = null
      fetch(url.toString())
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText)
          }
          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = document.createElement('a')
          objectURL = URL.createObjectURL(
            new Blob(
              [byteArray],
              { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            )
          )
          link.href = objectURL
          const filename = this.active ? this.$t('road-maintenance.patrol-service-active-orders') : this.$t('road-maintenance.patrol-service-inactive-orders')
          link.download = `${filename}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => {
          this.exportLoading = false
          if (objectURL) URL.revokeObjectURL(objectURL)
        })
    }
  }
}
</script>
