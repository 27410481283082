<template>
  <v-container
    fluid
    style="position: relative"
  >
    <v-card :disabled="fetchingData">
      <v-data-table
        v-stripped
        class="elevation-0"
        :headers="headers"
        :items="transformedData"
        :server-items-length="pagination.total"
        :items-per-page="pagination.perPage"
        :options.sync="options"
        disable-sort
        :footer-props="{
          itemsPerPageOptions: itemsPerPageOptions
        }"
        :loading="fetchingData"
      >
        <!-- Header -->
        <template #top>
          <v-toolbar
            flat
            class="toolbar-container"
          >
            <div class="header-row">
              <v-toolbar-title class="toolbar-title" v-text="$t('base/patrol-vehicle.active-work-orders')" />
              <v-col>
                <v-btn
                  icon
                  @click="getItems()"
                >
                  <v-icon style="font-size: 2rem;">mdi-refresh</v-icon>
                </v-btn>
              </v-col>

              <v-btn
                v-if="canCreate"
                rounded
                color="primary"
                class="white--text pr-3"
                style="font-size: 1.5rem; padding-top: 1rem; padding-bottom: 1rem; margin-right: 0; height: 3rem;"
                @click.prevent="handleCreateBtnClick"
              >
                <v-icon
                  class="mr-2"
                  style="font-size: 1.5rem;"
                >
                  mdi-plus
                </v-icon>
                {{ $t('base/patrol-vehicle.create-work-order-btn') }}
              </v-btn>
            </div>
            <v-dialog
              v-model="createOrUpdateDialog"
              content-class="elevation-0"
              fullscreen
              scrollable
              @keydown.enter.prevent="saveConfirmed"
              @keydown.esc.prevent="closeEditDialog"
            >
              <v-card
              >
                <v-card-title class="title">
                  {{ dialogTitle }}
                </v-card-title>
                <v-divider style="border-top: 2px solid #C0C0C0;"/>
                <v-card-text style="padding: 0; margin: 0;">
                  <v-form @submit.prevent="saveConfirmed">
                    <v-container class="mobile-form">
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="tipsFormData['location']"
                            :disabled="clickedProcessing"
                            filled
                            :error-messages="validationErrors['location']"
                            @input="clearValidationError('location')"
                            @click="openLocationModal"
                            class="location"
                            readonly
                            :height="'5rem'"
                            append-icon="mdi-map-marker"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span>{{ $t('base/patrol-vehicle.tips_form_choose_location_field') }}
                            </template>
                          </v-textarea>
                          <v-select
                            v-model="tipsFormData['orderType']"
                            :items="work_order_types"
                            :item-text="item => $t(item.description)"
                            item-value="id"
                            :disabled="clickedProcessing || editing"
                            filled
                            clearable
                            :error-messages="validationErrors['orderType']"
                            @input="clearValidationError('orderType')"
                            @change="clearValidationError('orderType')"
                            class="orderType"
                            :height="'5rem'"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span>{{ $t('base/patrol-vehicle.tips_form_choose_tip_of_order_field') }}
                            </template>
                          </v-select>
                          <v-select
                            v-model="tipsFormData['cityDistrict']"
                            :items="city_districts"
                            item-text="name"
                            item-value="id"
                            :disabled="clickedProcessing"
                            filled
                            clearable
                            :error-messages="validationErrors['cityDistrict']"
                            @input="clearValidationError('cityDistrict')"
                            @change="clearValidationError('cityDistrict')"
                            class="cityDistrict"
                            :height="'5rem'"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span>Gradska četvrt
                            </template>
                          </v-select>
                          <v-select
                            v-model="tipsFormData['subjectType']"
                            :items="subject_types"
                            item-text="road_administration_name"
                            item-value="id"
                            :disabled="clickedProcessing"
                            filled
                            clearable
                            :error-messages="validationErrors['subjectType']"
                            @input="clearValidationError('subjectType')"
                            @change="clearValidationError('subjectType')"
                            class="subjectType"
                            :height="'5rem'"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span>{{ $t('base/patrol-vehicle.tips_form_choose_tip_of_subject_field') }}
                            </template>
                          </v-select>
                          <v-select
                            v-model="tipsFormData['category']"
                            :items="categories"
                            :item-text="item => $t(item.description)"
                            item-value="id"
                            :disabled="clickedProcessing"
                            filled
                            clearable
                            :error-messages="validationErrors['category']"
                            @input="clearValidationError('category')"
                            @change="clearValidationError('category')"
                            class="category"
                            :height="'5rem'"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span>{{ $t('base/patrol-vehicle.tips_form_choose_category_field') }}
                            </template>
                          </v-select>
                          <v-select
                            v-model="tipsFormData['hazardType']"
                            :items="hazard_types"
                            :item-text="item => $t(item.description)"
                            item-value="id"
                            :disabled="clickedProcessing"
                            filled
                            clearable
                            :error-messages="validationErrors['hazardType']"
                            @input="clearValidationError('hazardType')"
                            @change="clearValidationError('hazardType')"
                            class="hazardType"
                            :height="'5rem'"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span>{{ $t('base/patrol-vehicle.tips_form_choose_hazard_field') }}
                            </template>
                          </v-select>
                          <v-textarea
                            :label="$t('base/patrol-vehicle.tips_form_address_field')"
                            v-model="tipsFormData['address']"
                            filled
                            clearable
                            :disabled="clickedProcessing"
                            append-icon="mdi-home"
                          />
                          <v-textarea
                            :label="$t('base/patrol-vehicle.tips_form_description_field')"
                            v-model="tipsFormData['description']"
                            filled
                            clearable
                            :disabled="clickedProcessing"
                            append-icon="mdi-comment-processing-outline"
                          >
                          </v-textarea>
<!--                          Recorded audio label-->
                          <div v-if="editing && audioUrl">
                            <v-row>
                              <v-col>
                                <div class="d-flex align-center">
                                  <label>
                                    {{ $t('base/patrol-vehicle.tips_form_recorded_audio_label') }}
                                  </label>
                                </div>
                              </v-col>
                            </v-row>
                            <v-divider style="border-top: 1px solid black; margin-bottom: 1rem;"/>
                          </div>
<!--                          Recorded audio preview-->
                          <div v-if="editing && audioUrlFromEdit" class="mb-5 text-center" style=" padding: 0.5rem;" :key="audioKeyy">
                            <v-row style="height: 100%; margin: 0;">
                              <v-col cols="12" style="height: 100%;">
                                <audio :src="audioUrlFromEdit" controls></audio>
                              </v-col>
                            </v-row>
                            <v-row style="margin: 0; height: 100%;">
                              <v-col style="height: 100%; padding: 0;">
                                <v-btn @click="downloadAudio" :disabled="!editing" style="width: 100%; height: 3rem; margin: 0;">
                                  {{ $t('base/patrol-vehicle.tips_form_recorded_audio_download') }} <v-icon> mdi-file-download </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>

                          <div v-if="editing && selectedImages && selectedImages.length !== 0">
                            <v-row>
                              <v-col>
                                <div class="d-flex align-center">
                                  <label>
                                    {{ $t('base/patrol-vehicle.tips_form_uploaded_images_button') }}
                                  </label>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
<!--                          Uploaded images preview-->
                          <div v-if="editing && selectedImages && selectedImages.length !== 0" class="mb-5" style="border: 1px rgb(128,128,128) solid; height: 7rem;">
                            <v-row style="height: 100%; margin: 0; padding: 0;">
                              <v-col cols="12" style="height: 100%; padding: 0;">
                                  <v-btn @click="showImagesPreview" style="width: 100%; height: 100%; padding: 0;">
                                    <div class="text-center">
                                      <label style="display: block; text-align: center;">
                                        {{ $t('base/patrol-vehicle.tips_form_click_for_to_see_images') }}
                                      </label>
                                      <label style="display: block; text-align: center;">
                                        <img
                                            src="/img/icons/images-album.png"
                                            alt="Upload-img"
                                            style="background-color: transparent; margin-bottom: 0; width: 30px; height: 30px;"
                                        >
                                    </label>
                                    </div>
                                  </v-btn>
                              </v-col>
                            </v-row>
                          </div>
<!--                          Record new audio label-->
                          <div v-if="!clickedProcessing">
                            <v-row>
                              <v-col cols="12">
                                <label>
                                  {{ $t('base/patrol-vehicle.tips_form_add_audio_recording') }}
                                </label>
                              </v-col>
                            </v-row>
                            <v-divider style="border-top: 1px solid black; margin-bottom: 1rem;"/>
                          </div>
<!--                          Record new audio preview-->
                          <div v-if="!clickedProcessing" class="mb-5 text-center" style=" padding: 0.5rem;">
                            <v-row>
                              <v-col cols="12">
                                <v-btn @click="record" :disabled="clickedProcessing" style="width: 100%; height: 3rem;">
                                  <v-icon> mdi-microphone </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <audio :src="recordedAudioUrl" :key="audioKey" controls></audio>
                              </v-col>
                            </v-row>
                          </div>
<!--                          Resolving title - show only if order type is NOTIFICATION or user chose to process work order-->
                          <div v-if="shouldShow || clickedProcessing" class="text-center  mb-5 resolving-title" style="font-size: 20px; font-weight: bold;"
                          >
                            <v-row>
                              <v-col cols="12">
                                <label>
                                  {{ $t('base/patrol-vehicle.tips_form_resolving_title') }}
                                </label>
                              </v-col>
                            </v-row>
                            <v-divider v-if="shouldShow || clickedProcessing" style="border-top: 1px solid black; margin-bottom: 2rem; margin-top: 0.3rem;"/>
                          </div>
<!--                          Upload new images label-->
                          <div>
                            <v-row>
                              <v-col cols="12">
                                <label>
                                  {{ $t('base/patrol-vehicle.tips_form_upload_images') }}
                                </label>
                              </v-col>
                            </v-row>
                          </div>
                          <v-divider style="border-top: 1px solid black; margin-bottom: 1rem;"/>
<!--                          Upload new images preview-->
                          <div class="mb-5" style="padding: 0.5rem;">
                            <v-row>
                              <v-col>
                                <v-btn
                                  style="width: 100%; height: 3rem; padding: 0;"
                                  @click="openFileInput"
                                >
                                  <v-icon>mdi-upload</v-icon>
                                  <input
                                    ref="fileInput"
                                    id="image_upload"
                                    type="file"
                                    enctype="multipart/form-data"
                                    multiple
                                    :accept="'image/*'"
                                    :max="3"
                                    hidden
                                    @change="imageSelected($event)"
                                  >
                                </v-btn>
                              </v-col>
                              <v-col>
                                <div style="position: relative; display: inline-block; width: 100%; height: 100%;">
                                  <v-btn @click="showNewImagesPreview" style="width: 100%; height: 3rem;">
                                    <img
                                        src="/img/icons/images-album.png"
                                        alt="Upload-img"
                                        style="background-color: transparent;"
                                        width="30"
                                        height="30"
                                    >
                                  </v-btn>
                                  <div class="image-count-btn">{{ selectedNewImages.length }}</div>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
<!--                          Date picker preview-->
                          <div v-if="shouldShow || clickedProcessing">
                            <v-text-field
                              v-model="tipsFormData['dateInput']"
                              append-icon="mdi-calendar-month-outline"
                              :error-messages="validationErrors['dateInput']"
                              @input="clearValidationError('dateInput')"
                              @change="clearValidationError('timeInput')"
                              filled
                              readonly
                              clearable
                              :height="'4rem'"
                              @click="showDatePicker=true"
                            >
                              <template #label>
                                <span class="red--text"><strong>* </strong></span>{{ $t('base/patrol-vehicle.tips_form_choose_date_label') }}
                              </template>
                            </v-text-field>
                            <v-dialog
                              v-model="showDatePicker"
                              style="padding: 0; margin: 0;"
                            >
                              <v-card
                                style="padding: 0; margin: 0;"
                              >
                                <v-card-text
                                  style="padding: 0; margin: 0;"
                                >
                                  <v-date-picker
                                    v-model="tipsFormData['datePicker']"
                                    first-day-of-week="1"
                                    full-width
                                    @change="clearValidationError('dateInput')"
                                    style="padding: 0; margin: 0;"
                                  >
                                    <v-spacer />
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="onDatePicker"
                                    >
                                      {{ $t('base.ok') }}
                                    </v-btn>
                                  </v-date-picker>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                          </div>

<!--                          Time picker preview-->
                          <div v-if="shouldShow || clickedProcessing">
                            <v-text-field
                              v-model="tipsFormData['timeInput']"
                              append-icon="mdi-clock-outline"
                              :error-messages="validationErrors['timeInput']"
                              @input="clearValidationError('timeInput')"
                              @change="clearValidationError('timeInput')"
                              filled
                              readonly
                              clearable
                              :height="'4rem'"
                              @click="showTimePicker=true"
                            >
                              <template #label>
                                <span class="red--text"><strong>* </strong></span>{{ $t('base/patrol-vehicle.tips_form_choose_time_label') }}
                              </template>
                            </v-text-field>
                            <v-dialog
                              v-model="showTimePicker"
                              style="padding: 0; margin: 0;"
                            >
                              <v-card
                                style="padding: 0; margin: 0;"
                              >
                                <v-card-text
                                  style="padding: 0; margin: 0;"
                                >
                                  <v-time-picker
                                    v-model="tipsFormData['timePicker']"
                                    format="24hr"
                                    first-day-of-week="1"
                                    full-width
                                    @change="clearValidationError('timeInput')"
                                    style="padding: 0; margin: 0;"
                                  >
                                    <v-spacer />
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="onTimePicker"
                                    >
                                      {{ $t('base.ok') }}
                                    </v-btn>
                                  </v-time-picker>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                          </div>
<!--                          Buttons - send and cancel-->
                          <div class="d-flex justify-center text-center footer"
                          >
                            <v-row style="margin-bottom: 1rem;">
                              <v-col cols="6">
                                <v-btn
                                  type="button"
                                  color="primary"
                                  @click="closeTipDialog"
                                  style="width: 95%; height: 3rem"
                                >
                                  {{ $t('base/patrol-vehicle.tips_form_quit_button') }}
                                </v-btn>
                              </v-col>
                              <v-col cols="6">
                                <v-btn
                                  type="submit"
                                  color="primary"
                                  class="mr-2"
                                  style="width: 95%; height: 3rem"
                                >
                                  {{ $t('base/patrol-vehicle.tips_form_send_button') }}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>

            <!-- Preview selected images - chosen images when editing - Modal -->
            <v-dialog v-model="showImageModal" fullscreen persistent>
              <v-card>
                <v-card-title>{{ $t('base/patrol-vehicle.tips_form_images_dialog_title') }}</v-card-title>
                <v-card-text>
                  <div class="image-preview-modal" :key="selectedImagesKey">
                    <template v-for="(image, key) in imagesFromEdit">
                      <div class="image-container" :key="key">
                        <div class="image-wrapper">
                          <img :src="image" :key="key" alt="Selected Image" class="selected-image">
                          <v-btn class="download-button" @click="downloadImage(createImageUrlForDownload(image), 'image_' + key + '.jpg')">
                            <span>{{ $t('base/patrol-vehicle.download') }} <v-icon >mdi-download</v-icon></span>
                          </v-btn>
                        </div>
                        <v-btn v-if="!editing" icon class="delete-button" @click="deleteImage(key)">
                          <v-icon class="delete-icon" style="font-size: 50px; color: red;">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" @click="closeImageModal" style="font-size: 26px; padding: 20px 20px; margin-bottom: 20px">{{ $t('base.close') }}</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Preview selected images - chosen images when creating - Modal -->
            <v-dialog v-model="showNewImageModal" fullscreen persistent>
              <v-card>
                <v-card-title>{{ $t('base/patrol-vehicle.tips_form_images_dialog_title') }}</v-card-title>
                <v-card-text>
                  <div class="image-preview-modal">
                    <template v-for="(image, key) in selectedNewImages">
                      <div class="image-container" :key="key">
                        <div class="image-wrapper">
                          <img :src="image.dataUrl" :key="key" alt="Selected Image" class="selected-image">
                        </div>
                        <v-btn v-if="!editing" icon class="delete-button" @click="deleteImage(key)">
                          <v-icon class="delete-icon" style="font-size: 50px; color: red;">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" @click="closeNewImageModal" style="font-size: 26px; padding: 20px 20px; margin-bottom: 20px">{{ $t('base/patrol-vehicle.tips_form_images_dialog_close_button') }}</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>

<!--            Recording progress and stop recording modal-->
            <v-dialog v-model="showRecordingAudioModal" persistent>
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                  <div class="recording-icon">
                    <div class="icon-container">
                      <v-icon class="red-blink">mdi-microphone</v-icon>
                    </div>
                    <span> {{ recordingTimer }} </span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" @click="stopRecordingAudioModal" style="height: 3rem; width: 100%;">{{ $t('base/patrol-vehicle.tips_form_recording_stop_button') }}</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <LocationMapModal
              :location-dialog="showLocationModal"
              :isEditing="editing"
              :latlng="[tipsFormData.latitude, tipsFormData.longitude]"
              @resolved-address="getResolvedAddress"
              @clicked-address="getClickedAddress"
              @set-city-district-and-subject-type="setCityDistrictAndSubjectType"
              @close-dialog="closeLocationMapModal"
            />
          </v-toolbar>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('base/patrol-vehicle.id')"
            class="mr-5 ml-5 mb-2"
            hide-details
          ></v-text-field>
          <v-divider />
        </template>

        <template
          #[`item.actions`]="{ item }"
        >
          <v-menu
            offset-y
            offset-overflow
            left
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                style="font-size: 2.5rem !important;"
              >
                mdi-dots-vertical
              </v-icon>
            </template>

            <v-list class="pa-0">
              <v-list-item
                v-if="item.can_update === true"
                class="d-flex align-center py-2"
                link
                @click="openEditDialog(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2" style="height: 2rem;">
                  <v-icon small style="font-size: 1.3rem;">
                    mdi-pencil
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  <span style="font-size: 1.5rem;"> {{ $t('base.edit') }} </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template
          #[`item.processing`]="{ item }"
        >
          <v-menu
            offset-y
            offset-overflow
            left
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                style="font-size: 2.5rem !important;"
                v-on="on"
                @click="openProcessingDialog(item)"
              >
                mdi-file-document
              </v-icon>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <exception-dialog
      :error-title="exceptionTitle"
      :error-message="exceptionMessage"
      :exception-dialog="exceptionDialog"
      @close-exception-dialog="closeExceptionDialog"
    />
  </v-container>
</template>

<script>
import LocationMapModal from './LocationMapModal.vue'
import ExceptionDialog from '@/global/components/ExceptionDialog'
import { forEach, isEmpty, pickBy } from 'lodash'
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: g, mapActions: a } = createNamespacedHelpers('road-maintenance/patrol-vehicle')

export default {
  components: {
    ExceptionDialog,
    LocationMapModal
  },

  props: {
    module: {
      type: String,
      default: 'base',
      required: true
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    },
    pagination: {
      type: Object,
      required: true
    },
    sort: {
      type: Object,
      default: () => ({})
    },
    fetch: {
      type: Function,
      required: true
    },
    fetchConfig: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      createOrUpdateDialog: false,
      exceptionDialog: false,
      exceptionTitle: '',
      exceptionMessage: '',
      activeColumns: [],
      oldActiveColumns: [],
      options: {
        page: 1,
        itemsPerPage: null,
        sortBy: [],
        sortDesc: []
      },
      itemsInitiallyLoad: false,
      activeFilters: {},
      validationErrors: {},
      fetchingData: false,
      companyScopeId: null,
      selectedImages: [],
      selectedImagesForFormData: [],
      showImageModal: false,
      showNewImageModal: false,
      showLocationModal: false,
      resolvedAddress: '',
      warningMessage: this.$t('base/patrol-vehicle.tips_form_choose_image_button_warning'),
      overSizedImagesMessage: this.$t('base/patrol-vehicle.tips_form_oversize_images'),
      isRecording: false,
      mediaRecorder: null,
      recordedChunks: [],
      tipsFormData: {
        id: '',
        location: '',
        latitude: 0,
        longitude: 0,
        orderType: '',
        subjectType: '',
        category: '',
        hazardType: '',
        address: '',
        description: '',
        dateInput: '',
        datePicker: '',
        timeInput: '',
        timePicker: '',
        images: [],
        recordedAudio: [],
        audioBlob: null
      },
      showDatePicker: false,
      showTimePicker: false,
      shouldShow: false,
      clickedProcessing: false,
      editing: false,
      stoppedRecordingAudio: false,
      showRecordingAudioModal: false,
      editItemId: null,
      processItemId: null,
      audioUrl: null,
      selectedNewImages: [],
      recordingStartTime: null,
      recordingTimer: 0,
      recordingIntervalId: 0,
      recordedAudioUrl: null,
      audioBlobForSending: null,
      audioKey: 0,
      transformedData: [],
      itemsPerPageOptions: [5, 10, 15],
      canCreate: false,
      canRead: false,
      canUpdate: false,
      notificationOrderTypeString: this.$t('road_maintenance/work_order_type.record'),
      maxSizePerImageInPixels: 1024,
      orderTypes: [],
      subjectTypes: [],
      hazardTypes: [],
      categoryTypes: [],
      orderTypesIds: [1, 2],
      selectedImagesKey: 0,
      audioKeyy: 0,
      imagesFromEdit: [],
      audioUrlFromEdit: null,
      search: '',
      searchTimeoutId: 0,
      dialogTitle: 'Kreiranje radnog naloga'
    }
  },
  computed: {
    ...g({
      work_order_types: 'work_order_types',
      subject_types: 'subject_types',
      categories: 'categories',
      hazard_types: 'hazard_types',
      city_districts: 'city_districts',
      permissions: 'permissions'
    }),

    filteredOptions () {
      return {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0] ? this.options.sortBy[0] : null,
        sortDesc: this.options.sortDesc[0] ? this.options.sortDesc[0] : null
      }
    },

    sortOptions () {
      return {
        by: this.filteredOptions.sortBy,
        desc: this.filteredOptions.sortDesc,
        ...this.options.sortReset && { reset: this.options.sortReset }
      }
    },

    headers () {
      const data = []
      const columns = ['id', 'subject_report', 'category', 'address', 'processing']
      const columnsTranslated = [this.$t('base/patrol-vehicle.id'), this.$t('base/patrol-vehicle.subject'), this.$t('base/patrol-vehicle.category'), this.$t('base/patrol-vehicle.address'), this.$t('base/patrol-vehicle.processing')]

      forEach(columns, function (column, key) {
        data.push({
          text: columnsTranslated[key],
          value: column,
          sortable: true,
          align: 'left',
          class: 'custom-data-table-header'
        })
      })

      data.push({
        text: this.$t('base/patrol-vehicle.actions'),
        value: 'actions',
        width: '5%',
        align: 'center',
        class: 'custom-data-table-header',
        sortable: false
      })

      return data
    }
  },

  watch: {
    pagination: {
      deep: true,
      handler (value) {
        this.options.itemsPerPage = value.perPage
        this.options.page = value.currentPage
      }
    },

    search: {
      deep: true,
      handler (value) {
        const that = this
        clearTimeout(this.searchTimeoutId)
        this.searchTimeoutId = setTimeout(() => {
          that.getItems()
        }, 500)
      }
    },

    sort (sort) {
      if (!isEmpty(sort)) {
        this.options.sortBy[0] = sort.by
        this.options.sortDesc[0] = sort.desc
      }
    },

    selectedImages: {
      deep: true,
      handler (newImages) {
        this.imagesFromEdit = newImages
      }
    },

    audioUrl: {
      deep: true,
      handler (newAudio) {
        this.audioUrlFromEdit = newAudio
      }
    },

    permissions (value) {
      if (value) {
        this.canCreate = value[0].create
        this.canRead = value[0].read
        this.canUpdate = value[0].update
      }
    },

    'tipsFormData.orderType' (newValue) {
      this.tipsFormData.dateInput = ''
      this.tipsFormData.timeInput = ''
      if (newValue === this.orderTypesIds[1]) {
        this.shouldShow = true
      }
      else {
        if (this.shouldShow) {
          this.shouldShow = false
        }
      }
    },

    'tipsFormData.dateInput' () {
      if (this.validationErrors.dateInput) {
        this.validationErrors.dateInput = ''
      }
    },

    'tipsFormData.timeInput' () {
      if (this.validationErrors.timeInput) {
        this.validationErrors.timeInput = ''
      }
    },

    list (listItems) {
      if (!this.options.itemsPerPage) {
        this.options.itemsPerPage = this.pagination.perPage
      }
      this.transformedData = listItems.map(item => {
        return {
          id: item.id,
          subject_report: item.subject_report && typeof item.subject_report === 'object' && item.subject_report.road_administration_name !== 'null' ? item.subject_report.road_administration_name : '',
          category: item.category && typeof item.category === 'object' && item.category.description !== 'null' ? this.$t(item.category.description) : '',
          address: item.address !== 'null' ? item.address : '',
          work_order_type: item.work_order_type && typeof item.work_order_type === 'object' && item.work_order_type.description !== 'null' ? this.$t(item.work_order_type.description) : '',
          can_update: item.canUpdate !== null ? item.canUpdate : 'false'
        }
      })
    },

    filteredOptions: {
      deep: true,
      handler () {
        if (this.fetchConfig) {
          this.fetchConfig()
        }
        else {
          if (this.itemsInitiallyLoad) {
            this.getItems()
          }
        }
      }
    }
  },

  async created () {
    await this.fetchData()
    await this.getPermissions()
    this.setPreviouslyVisitedStorePagination()
    await this.getItems()
  },
  methods: {
    ...a([
      'fetchSelectOptions',
      'fetchPermissions'
    ]),
    async fetchData () {
      await this.fetchSelectOptions()
    },

    async getPermissions () {
      await this.fetchPermissions()
    },

    setCityDistrictAndSubjectType (result) {
      if (result && !isEmpty(result) && result.location_id && result.subject_report_id) {
        this.tipsFormData.cityDistrict = result.location_id
        this.tipsFormData.subjectType = result.subject_report_id
      }
    },

    setPreviouslyVisitedStorePagination () {
      this.options.page = this.pagination.currentPage
    },

    createImageUrlForDownload (url) {
      const parts = url.split('/road-maintenance')
      return '/public/road-maintenance' + (parts[1] || '')
    },

    downloadImage (url, imageName) {
      const link = document.createElement('a')
      link.href = url
      link.download = imageName
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    openFileInput () {
      this.$refs.fileInput.click()
    },

    openProcessingDialog (item) {
      const obj = this.findObjectById(item.id)

      if (obj) {
        this.processItemId = item.id
        this.tipsFormData.location = obj.location
        this.tipsFormData.orderType = obj.patrol_order_type.id
        this.tipsFormData.subjectType = obj.subject_report.id
        this.tipsFormData.category = obj && obj.category && obj.category.id ? obj.category.id : null
        this.tipsFormData.hazardType = obj.hazard_level.id
        this.tipsFormData.cityDistrict = obj.city_district && obj.city_district.id ? obj.city_district.id : null
        this.tipsFormData.address = obj.address !== 'null' ? obj.address : ''
        this.tipsFormData.description = obj.description !== 'null' ? obj.description : ''
        this.selectedImages = []
        this.selectedImages = obj.images
        this.tipsFormData.audioBlob = obj.audio
        this.audioUrl = obj.audio

        this.dialogTitle = 'Obrađivanje radnog naloga'
        this.clickedProcessing = true
        this.createOrUpdateDialog = true
        this.$nextTick(() => {
          const firstField = document.querySelector('.footer')
          if (firstField) {
            firstField.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
          }
        })
      }
    },

    onDatePicker () {
      this.showDatePicker = false
      this.tipsFormData.dateInput = this.tipsFormData.datePicker
    },

    onTimePicker () {
      this.showTimePicker = false
      this.tipsFormData.timeInput = this.tipsFormData.timePicker
    },

    getResolvedAddress (resolvedAddress) {
      this.tipsFormData.location = resolvedAddress
      if (resolvedAddress && this.validationErrors.location) {
        delete this.validationErrors.location
      }
    },

    getClickedAddress (address) {
      if (address && address.length && address[0] && !isEmpty(address[0]) && address[0].lat && address[0].lon) {
        this.tipsFormData.latitude = address[0].lat
        this.tipsFormData.longitude = address[0].lon
      }
    },

    closeLocationMapModal () {
      this.showLocationModal = false
    },

    imageSelected (event, index) {
      const selectedFiles = event.target.files
      const maxImages = 3
      const warningMessage = this.warningMessage
      const maxSizePerImageInPixels = this.maxSizePerImageInPixels
      const maxMBPerResizedImage = 3
      const resizedImages = []
      if (selectedFiles && selectedFiles.length <= maxImages) {
        const remainingSlots = maxImages - this.selectedNewImages.length

        for (let i = 0; i < Math.min(selectedFiles.length, remainingSlots); i++) {
          this.resizeImage(selectedFiles[i], maxSizePerImageInPixels).then(({ dataUrl, sizeInMB }) => {
            const imageName = selectedFiles[i].name
            const imageDataForCheckingItsSize = {
              name: imageName,
              size: sizeInMB
            }
            const newImageData = {
              name: imageName,
              dataUrl: dataUrl
            }
            resizedImages.push(imageDataForCheckingItsSize)
            this.selectedNewImages.push(newImageData)
          }).catch((err) => {
            console.error(err)
          })
        }
        if (selectedFiles.length > remainingSlots) {
          alert(warningMessage)
        }
        const result = this.checkSizeOfResizedImages(resizedImages, maxMBPerResizedImage)
        if (result.length > 0) {
          alert(this.overSizedImagesMessage)
        }
      }
      else {
        alert(warningMessage)
      }
    },

    dataURItoBlob (dataURI) {
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1])
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
      const max = bytes.length
      const ia = new Uint8Array(max)
      for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i)
      return new Blob([ia], { type: mime })
    },

    checkSizeOfResizedImages (images, maxSizeInMBPerImage) {
      const result = []
      for (let i = 0; i < images.length; i++) {
        if (images[i].size > maxSizeInMBPerImage) {
          result.push(images[i].name)
        }
      }
      return result
    },

    resizeImage (file, maxSize) {
      const reader = new FileReader()
      const image = new Image()
      const canvas = document.createElement('canvas')

      const resize = () => {
        let { width, height } = image

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        }
        else if (height > maxSize) {
          width *= maxSize / height
          height = maxSize
        }

        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)

        const dataUrl = canvas.toDataURL('image/jpeg')
        const bytes = dataUrl.length * 0.75
        const megabytes = bytes / (1024 * 1024)

        return { dataUrl, sizeInMB: megabytes }
      }

      return new Promise((resolve, reject) => {
        if (!file.type.match(/image.*/)) {
          reject(new Error('Not an image'))
          return
        }

        reader.onload = (readerEvent) => {
          image.onload = () => {
            const { dataUrl, sizeInMB } = resize()
            resolve({ dataUrl, sizeInMB })
          }
          image.src = readerEvent.target.result
        }

        reader.readAsDataURL(file)
      })
    },

    stopRecordingAudioModal () {
      if (!this.mediaRecorder) {
        this.showRecordingAudioModal = false
        return
      }

      clearInterval(this.recordingIntervalId)
      this.showRecordingAudioModal = false

      if (this.isRecording) {
        this.mediaRecorder.stop()

        const recordedAudioDurationInSeconds = (Date.now() - this.recordingStartTime) / 1000

        if (this.mediaRecorder.stream) {
          this.mediaRecorder.stream.getTracks().forEach((track) => track.stop())
        }

        if (recordedAudioDurationInSeconds > 60) {
          alert(this.$t('base/patrol-vehicle.tips_form_recording_limit_exceeded_error'))
        }
        else {
          this.mediaRecorder.onstop = () => {
            const audioBlob = new Blob(this.recordedChunks, { type: 'audio/mpeg' })
            this.audioBlobForSending = audioBlob
            this.recordedAudioUrl = URL.createObjectURL(audioBlob)
            this.recordedChunks = []
            this.mediaRecorder = null
          }
        }

        this.recordingTimer = 0
        this.isRecording = false
        this.stoppedRecordingAudio = true
      }
    },

    async record () {
      try {
        this.showRecordingAudioModal = true
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        this.mediaRecorder = new MediaRecorder(stream)
        this.recordedChunks = []
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.recordedChunks.push(event.data)
          }
        }
        this.mediaRecorder.onstart = () => {
          this.recordingStartTime = Date.now()
          this.isRecording = true

          this.recordingIntervalId = setInterval(() => {
            this.recordingTimer += 1
          }, 1000)
        }

        this.mediaRecorder.start()
      }
      catch (error) {
        if (error.name === 'NotAllowedError') {
          this.showRecordingAudioModal = false
        }
        else {
          console.error('Error starting recording:', error)
        }
      }
    },

    async downloadAudio () {
      try {
        const response = await api()[this.module].get('patrol-vehicle/audio/download/' + this.editItemId, { responseType: 'blob' })

        if (!response.url) {
          alert(this.$t('base/patrol-vehicle.no_audio_for_download'))
        }
        else {
          const link = document.createElement('a')
          link.href = response.url
          link.download = 'audio.mp3'
          link.click()
        }
      }
      catch (error) {
        console.error('Error downloading audio:', error)
      }
    },

    showImagesPreview () {
      this.showImageModal = true
    },

    showNewImagesPreview () {
      this.showNewImageModal = true
    },

    openLocationModal () {
      if (!this.clickedProcessing) {
        this.showLocationModal = true
      }
    },

    closeImageModal () {
      this.showImageModal = false
    },

    closeNewImageModal () {
      this.showNewImageModal = false
    },

    deleteImage (index) {
      this.selectedNewImages.splice(index, 1)
    },

    clearFormData () {
      this.selectedNewImages = []
      this.editing = false
      this.clickedProcessing = false
      this.selectedImagesForFormData = []
      this.recordedChunks = []
      this.audioUrl = null
      this.recordedAudioUrl = null
      this.audioKey += 1
      this.mediaRecorder = null
      this.isRecording = false
      this.tipsFormData = {
        id: '',
        location: '',
        latitude: 0,
        longitude: 0,
        orderType: '',
        subjectType: '',
        category: '',
        hazardType: '',
        address: '',
        description: '',
        dateInput: '',
        datePicker: '',
        timeInput: '',
        timePicker: '',
        images: [],
        recordedAudio: []
      }
    },

    closeTipDialog () {
      this.clearFormData()
      this.validationErrors = {}
      this.createOrUpdateDialog = false
      this.shouldShow = false
      this.clickedProcessing = false
      this.editing = false
      this.recordedChunks = []
      this.selectedNewImages = []
    },

    async getItems (resetPage = false, companyScopeId = null) {
      if (resetPage) {
        this.options.page = 1
      }
      const options = this.filteredOptions
      const activeFilters = pickBy(this.activeFilters, (filter) => filter !== null)
      const params = {
        pagination: {
          perPage: options.itemsPerPage,
          currentPage: this.search ? 1 : options.page
        },
        sort: {
          by: options.sortBy,
          desc: options.sortDesc,
          ...this.options.sortReset && { reset: this.options.sortReset }
        },
        filters: { ...activeFilters },
        tipNumber: this.search,
        ...companyScopeId && { company_scope_id: companyScopeId }
      }
      try {
        this.fetchingData = true
        await this.fetch(params)
        if (options.itemsPerPage === null) {
          this.itemsInitiallyLoad = true
        }
      }
      finally {
        this.fetchingData = false
      }
    },

    async openEditDialog (item) {
      const obj = this.findObjectById(item.id)

      if (obj) {
        this.editItemId = item.id
        this.tipsFormData.id = item.id
        this.tipsFormData.latitude = obj.latitude
        this.tipsFormData.longitude = obj.longitude
        this.tipsFormData.location = obj.location
        this.tipsFormData.orderType = obj.patrol_order_type.id
        this.tipsFormData.subjectType = obj.subject_report.id
        this.tipsFormData.category = obj && obj.category && obj.category.id ? obj.category.id : null
        this.tipsFormData.hazardType = obj.hazard_level.id
        this.tipsFormData.cityDistrict = obj && obj.city_district && obj.city_district.id ? obj.city_district.id : null
        this.tipsFormData.address = obj.address !== 'null' ? obj.address : ''
        this.tipsFormData.description = obj.description !== 'null' ? obj.description : ''
        this.selectedImages = []
        this.selectedImages = obj.notification_images
        this.tipsFormData.audioBlob = obj.audio

        this.audioUrl = obj.audio
        this.dialogTitle = 'Uređivanje radnog naloga'
        this.createOrUpdateDialog = true
        this.editing = true
        this.selectedImagesKey++
        this.audioKeyy++

        this.$nextTick(() => {
          const firstField = document.querySelector('.location')
          if (firstField) {
            firstField.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
          }
        })
      }
    },

    findObjectById (id) {
      return this.list.find(obj => obj.id === id)
    },

    handleCreateBtnClick () {
      this.dialogTitle = 'Kreiranje radnog naloga'
      this.createOrUpdateDialog = true
      this.editing = false
      this.$nextTick(() => {
        const firstField = document.querySelector('.location')
        if (firstField) {
          firstField.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      })
    },

    closeEditDialog () {
      this.createOrUpdateDialog = false
      this.clearFormData()
      this.shouldShow = false
    },

    async saveConfirmed () {
      const validationErrors = this.checkFormDataValidationErrors()
      if (!validationErrors) {
        const formData = this.prepareFormDataForSending()
        const apiInstance = api()[this.module]

        try {
          let response
          if (this.editing) {
            response = await apiInstance.patch('work-orders/' + this.editItemId, this.tipsFormData)
          }
          else if (this.clickedProcessing) {
            const formData = new FormData()
            formData.append('date', this.tipsFormData.dateInput)
            formData.append('time', this.tipsFormData.timeInput)
            response = await apiInstance.post('patrol-vehicle/process-work-order/' + this.processItemId, formData)
          }
          else {
            response = await apiInstance.post('work-orders', formData)
          }

          if (response) {
            await this.sendImagesAndAudio(response.data.id)
            this.clearFormData()
            await this.getItems()
            this.closeEditDialog()
          }
        }
        catch (e) {
          console.log(e)
        }
      }
    },

    async sendImagesAndAudio (id) {
      if (!id) return

      try {
        if (this.selectedNewImages.length > 0) {
          const imageFormData = new FormData()
          for (const imageFile of this.selectedNewImages) {
            const blob = this.dataURItoBlob(imageFile.dataUrl)
            const file = new File([blob], imageFile.name, { type: 'image/jpeg' })
            imageFormData.append('images[]', file)
          }
          await api()[this.module].post('patrol-vehicle/sendImages/' + id, imageFormData)
        }

        if (this.audioBlobForSending) {
          const audioFormData = new FormData()
          audioFormData.append('recordedAudio[]', this.audioBlobForSending, 'audio.mp3')
          await api()[this.module].post('patrol-vehicle/sendAudio/' + id, audioFormData)
        }
      }
      catch (error) {
        console.error('Error sending images and audio:', error)
      }
    },

    prepareFormDataForSending () {
      const formData = new FormData()
      const {
        id,
        location,
        latitude,
        longitude,
        orderType,
        subjectType,
        category,
        hazardType,
        cityDistrict,
        address,
        description,
        dateInput,
        timeInput
      } = this.tipsFormData

      formData.append('id', id)
      formData.append('location', location)
      formData.append('latitude', latitude)
      formData.append('longitude', longitude)
      formData.append('orderType', orderType)
      formData.append('subjectType', subjectType)
      formData.append('category', category)
      formData.append('hazardType', hazardType)
      formData.append('cityDistrict', cityDistrict)
      formData.append('address', address)
      formData.append('description', description)
      formData.append('date', dateInput)
      formData.append('time', timeInput)

      return formData
    },

    checkFormDataValidationErrors () {
      this.validationErrors = {}
      const fieldOrder = [
        'location',
        'orderType',
        'subjectType',
        'category',
        'hazardType',
        'cityDistrict'
      ]
      const processedFieldOrder = [
        'dateInput',
        'timeInput'
      ]
      const processedAndBasicFieldOrder = [...fieldOrder, ...processedFieldOrder]

      const fieldErrors = this.createFieldErrors(fieldOrder)
      const processFieldErrors = this.createFieldErrors(processedFieldOrder)
      const mergedFieldErrors = this.createFieldErrors(processedAndBasicFieldOrder)

      if (this.tipsFormData.orderType === this.orderTypesIds[1]) {
        this.populateValidationErrors(processedAndBasicFieldOrder, mergedFieldErrors)
      }
      else if (this.clickedProcessing) {
        this.populateValidationErrors(processedFieldOrder, processFieldErrors)
      }
      else {
        this.populateValidationErrors(fieldOrder, fieldErrors)
      }

      const firstError = this.getFirstError(fieldOrder, processedFieldOrder, processedAndBasicFieldOrder)

      this.scrollIntoView(firstError)

      return Object.keys(this.validationErrors).length !== 0
    },

    createFieldErrors (fields) {
      const fieldErrors = {}
      fields.forEach((field) => {
        fieldErrors[field] = this.$t(`base/patrol-vehicle.tips_form_${field}_field_error`)
      })
      return fieldErrors
    },

    populateValidationErrors (fields, fieldErrors) {
      fields.forEach((field) => {
        if (!this.tipsFormData[field]) {
          this.validationErrors[field] = fieldErrors[field]
        }
      })
    },

    getFirstError (...fieldOrders) {
      for (const fields of fieldOrders) {
        for (const field of fields) {
          if (!this.tipsFormData[field]) {
            return field
          }
        }
      }
      return null
    },

    scrollIntoView (firstError) {
      if (firstError) {
        const firstErrorField = document.querySelector(`.${firstError}`)
        if (firstErrorField) {
          firstErrorField.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }
    },

    clearValidationError (index) {
      if (!this.tipsFormData[index]) {
        const fieldErrors = {
          location: this.$t('base/patrol-vehicle.tips_form_location_field_error'),
          orderType: this.$t('base/patrol-vehicle.tips_form_orderType_field_error'),
          subjectType: this.$t('base/patrol-vehicle.tips_form_subjectType_field_error'),
          category: this.$t('base/patrol-vehicle.tips_form_category_field_error'),
          hazardType: this.$t('base/patrol-vehicle.tips_form_hazardType_field_error'),
          dateInput: this.$t('base/patrol-vehicle.tips_form_dateInput_field_error'),
          timeInput: this.$t('base/patrol-vehicle.tips_form_timeInput_field_error')
        }

        this.validationErrors[index] = fieldErrors[index]
      }
      else {
        delete this.validationErrors[index]
      }
    },

    closeExceptionDialog () {
      this.exceptionDialog = false
    }
  }
}
</script>

<style scoped lang="scss">
.v-card__title {
  padding: 10px 8px 8px 16px;
  font-size: 1rem;
}

.table-data-preview {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
::v-deep .theme--light.v-tabs > .v-tabs-bar {
  width: 21%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

::v-deep .form-fields-card {
  border: 2px solid var(--v-grey-lighten-1-base) !important;
  .tabs-wrapper {
    border-top: 2px solid var(--v-grey-lighten-1-base);

    .v-window-item {
      min-height: 65vh;

      .form-inputs-card {
        border-radius: 0 !important;
      }
    }

    .v-item-group {
      &:first-of-type {
        border-right: 2px solid var(--v-grey-lighten-1-base) !important;

        .v-slide-group__wrapper {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 36px;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: normal;
  text-transform: none;
}
.v-tab--active {
  background: #E2E2E2;
}
::v-deep .v-tabs-slider-wrapper {
  height: 36px;
  width: 6px !important;
  .v-tabs-slider {
    background-color: #6aac49;
  }
}

.header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.toolbar-container {
  padding: 0 1rem;
}

.toolbar-title {
  color: #869DB6;
  font-size: 1.2rem;
}

.map-location {
  cursor: pointer;
}

::v-deep {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-size: 1rem !important;
      }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 1rem !important;
  }
}

.mobile-form {
  padding: 16px;
  background-color: white;
}

.image-wrapper {
  position: relative;
  margin-bottom: 10px;
}

.image-preview-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.image-container {
  margin-bottom: 10px;
}

.selected-image {
  border: 2px #D0D0D0 solid;
  max-width: 100%;
  max-height: 100%;
}

.image-preview-modal-creating {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image-container-creating {
  display: inline-block;
  text-align: center;
}

.image-wrapper-creating {
  position: relative;
  margin-bottom: 2rem;
}

.delete-button {
  display: block;
  margin: 50px auto;
  font-size: 50px;
  text-align: center;
}

.center-button {
  margin: 0 auto;
}

.full-screen-dialog {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  left: 0;
}

.transparent-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  box-shadow: none;
  text-align: left;
}

.image-button-container {
  position: relative;
  display: inline-block;
}

.image-count-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff5722;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-controls {
  margin-left: 20px;
}

.flex-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  padding: 10px 10px;
  background-color: transparent;
  text-align: center;
}

.red-blink {
  color: white;
  font-size: 3rem;
  border-radius: 20%;
  animation: blink 1s ease-in-out infinite;
}
.icon-container {
  background-color: transparent;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
}

@keyframes blink {
  0% {
    background-color: rgba(255, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  50% {
    background-color: rgba(255, 0, 0, 1);
  }
  75% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  100% {
    background-color: rgba(255, 0, 0, 0.2);
  }
}

.recording-icon {
  font-size: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-button {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
  color: blue;
}
</style>
