<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="index"
          class="d-flex child-flex"
          :cols="12 / images.length"
        >
          <v-img
            :src="image"
            :lazy-src="image"
            class="bg-grey-lighten-2"
          >
            <template #placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey-lighten-5"
                />
              </v-row>
            </template>
            <v-btn
              color="primary"
              primary
              class="ma-3"
              @click="downloadImage(image, 'Fotografija' + (index + 1))"
            >
              {{ $t('base/patrol-vehicle.download') }}
            </v-btn>
          </v-img>
        </v-col>
      </v-row>
      <v-row class="ma-0 flex flex-row-reverse mt-4">
        <v-btn
          color="primary"
          primary
          class="px-7 ml-2 mt-3"
          @click="closeDialog"
        >
          {{ $t('base.close') }}
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'ImageGallery',

  props: {
    images: {
      type: Array,
      default: () => ([])
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close-image-gallery')
    },
    async downloadImage (imageUrl, title) {
      const blob = await (await fetch(imageUrl)).blob()
      const url = URL.createObjectURL(blob)
      Object.assign(document.createElement('a'), { href: url, download: title + '.jpg' }).click()
      URL.revokeObjectURL(url)
    }
  }
}
</script>
