<template>
  <div
    :style="wrapperStyles"
  >
    <v-container fluid>
      <v-card>
        <v-card-text>
          <v-data-table
            v-show="true"
            v-stripped
            class="fill-height overflow-y-auto"
            :headers="header"
            item-key="id"
            :items="list"
            :options.sync="options"
            :server-items-length="pagination.total"
            :items-per-page="pagination.perPage"
            :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
            :sort-by.sync="options.sortBy"
            :sort-desc.sync="options.sortDesc"
            @update:options="getItems"
          >
            <template #top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-toolbar-items
                  class="ml-12 mt-6"
                >
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="selectedFilter"
                        label="Gradska četvrt ili subjekt dojave"
                        :items="filters"
                        item-value="id"
                        item-text="name"
                        clearable
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-toolbar-items>
                <v-spacer />
                <v-btn
                  v-if="canCreate"
                  color="primary"
                  rounded
                  class="mb-2"
                  @click="isDialogOpen = true"
                >
                  <v-icon
                    class="mr-2"
                  >
                    mdi-plus
                  </v-icon>
                  Dodaj novo
                </v-btn>
                <patrol-subscription-settings-upsert
                  :open-dialog="isDialogOpen"
                  :filters="filters"
                  :edit-item="editItem"
                  :permissions="permissions"
                  @pull-data="getItems"
                  @closed-dialog="closedPatrolSubscriptionSettingsUpsert"
                />
              </v-toolbar>
            </template>
            <template #[`item.sendAt`]="{ item }">
              {{ item.sendAt ? item.sendAt :'Odmah' }}
            </template>
            <template #[`item.isActive`]="{ item }">
              <v-icon
                v-if="item.isActive"
                size="23px"
                color="success"
                class="py-3"
              >
                mdi-check-circle
              </v-icon>
              <v-icon
                v-else
                size="23px"
                color="red"
                class="py-3"
              >
                mdi-close-circle
              </v-icon>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-menu
                v-if="canUpdate || canActivate || canDelete"
                offset-y
                offset-overflow
                left
              >
                <template #activator="{ on }">
                  <v-icon
                    v-on="on"
                  >
                    mdi-dots-vertical
                  </v-icon>
                </template>

                <v-list class="pa-0">
                  <v-list-item
                    v-if="canUpdate"
                    class="d-flex align-center list-item-min-height"
                    link
                    @click="openEditDialog(item)"
                  >
                    <v-list-item-icon class="align-self-center my-0 mr-2">
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="main-font-size">
                      {{ $t('base.edit') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canActivate"
                    class="d-flex align-center list-item-min-height"
                    link
                    @click="openActionDialog(item.id, item.isActive ? 'deactivate' : 'activate')"
                  >
                    <v-list-item-icon class="align-self-center my-0 mr-2">
                      <v-icon
                        v-if="item.isActive"
                        small
                      >
                        mdi-close-thick
                      </v-icon>
                      <v-icon
                        v-else
                        small
                      >
                        mdi-check-bold
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="main-font-size">
                      {{ item.isActive ? $t('base.deactivate') : $t('base.activate') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canDelete"
                    class="d-flex align-center list-item-min-height"
                    link
                    @click="openActionDialog(item.id, 'delete')"
                  >
                    <v-list-item-icon class="align-self-center my-0 mr-2">
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="main-font-size">
                      {{ $t('base.delete') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog
      v-model="actionDialog"
      persistent
      max-width="650"
    >
      <v-card>
        <v-row class="flex justify-space-between ma-0">
          <v-card-title> {{ actionType ? actionDialogMessages[actionType].title : '' }} </v-card-title>
          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="closeActionDialog"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <v-card-text> {{ actionType ? actionDialogMessages[actionType].body : '' }} </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red"
            text
            @click="actionConfirmed"
          >
            {{ actionType ? actionDialogMessages[actionType].btn : '' }}
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click="actionDialog = false"
          >
            {{ $t('road-maintenance.excavation_delete_order_modal_cancel_btn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import PatrolSubscriptionSettingsUpsert from '@/modules/road-maintenance-module/patrol-settings/subscriptions/components/PatrolSubscriptionSettingsUpsert.vue'
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/patrol-settings')
export default {
  name: 'PatrolSubscriptionSettingsTable',

  components: {
    PatrolSubscriptionSettingsUpsert
  },

  data () {
    return {
      filters: [],
      selectedFilter: null,
      editItem: null,
      isDialogOpen: false,
      actionDialog: false,
      actionType: false,
      actionItemId: false,
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['id'],
        sortDesc: [true]
      },
      actionDialogMessages: {
        delete: {
          title: 'Obriši email',
          body: 'Jeste li sigurni da želite obrisati ovaj email?',
          btn: 'Obriši'
        },
        activate: {
          title: 'Aktiviraj',
          body: 'Jeste li sigurni da želite aktivirati ovaj email?',
          btn: 'Aktiviraj'
        },
        deactivate: {
          title: 'Deaktiviraj',
          body: 'Jeste li sigurni da želite deaktivirati ovaj email?',
          btn: 'Deaktiviraj'
        }
      }
    }
  },

  computed: {
    ...mapGetters(['list', 'pagination', 'permissions']),
    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    },
    header () {
      return [
        {
          text: 'Gradska četvrt ili subjekt dojave',
          value: 'notificationCategory',
          align: 'left',
          class: 'data-table-header',
          sortable: false
        },
        {
          text: this.$t('road-maintenance/excavation-dropdown-type.email'),
          value: 'email',
          align: 'left',
          class: 'data-table-header',
          sortable: true
        },
        {
          text: this.$t('road-maintenance.send_time'),
          value: 'sendAt',
          align: 'left',
          class: 'data-table-header',
          sortable: false
        },
        {
          text: this.$t('road-maintenance.is_active'),
          value: 'isActive',
          align: 'left',
          class: 'data-table-header',
          sortable: false
        },
        {
          text: this.$t('base.actions'),
          value: 'actions',
          align: 'center',
          class: 'data-table-header',
          sortable: false
        }
      ]
    },
    canCreate () {
      const [permissions] = this.permissions
      return permissions?.createPatrolSettings
    },
    canUpdate () {
      const [permissions] = this.permissions
      return permissions?.updatePatrolSettings
    },
    canDelete () {
      const [permissions] = this.permissions
      return permissions?.deletePatrolSettings
    },
    canActivate () {
      const [permissions] = this.permissions
      return permissions?.activatePatrolSettings
    }
  },

  watch: {
    selectedFilter: {
      handler () {
        this.getItems()
      }
    }
  },

  created () {
    this.getCityDistricts()
    this.fetchPermissions()
    this.getItems()
  },

  methods: {
    ...mapActions(['fetch', 'fetchPermissions']),
    async getCityDistricts () {
      const response = await api()['road-maintenance'].get('patrol-subscription/filters')
      this.filters = response.data
    },
    async getItems () {
      const params = {
        notificationCategory: this.selectedFilter,
        pagination: {
          perPage: this.options.itemsPerPage,
          currentPage: this.options.page
        },
        sort: {
          by: this.options.sortBy[0] ?? null,
          desc: this.options.sortDesc[0] ?? null
        }
      }
      await this.fetch(params)
      if (this.pagination.currentPage > this.pagination.totalPages) {
        this.options.page = 1
      }
    },
    openEditDialog (item) {
      this.editItem = item.id
      this.isDialogOpen = true
    },
    openActionDialog (item, type) {
      this.actionType = type
      this.actionDialog = true
      this.actionItemId = item
    },
    closeActionDialog () {
      this.actionDialog = false
      this.actionType = false
    },
    actionConfirmed () {
      if (this.actionType) {
        switch (this.actionType) {
          case 'delete':
            this.deleteItem()
            break
          case 'activate':
          case 'deactivate':
            this.toogleIsActive()
            break
        }
      }
    },
    async toogleIsActive () {
      await api()['road-maintenance'].patch('patrol-subscription/' + this.actionItemId + '/' + this.actionType)
      await this.getItems()
      this.closeActionDialog()
    },
    async deleteItem () {
      await api()['road-maintenance'].delete('patrol-subscription/' + this.actionItemId)
      await this.getItems()
      this.closeActionDialog()
    },
    closedPatrolSubscriptionSettingsUpsert () {
      this.editItem = null
      this.isDialogOpen = false
    }
  }
}
</script>
