<template>
  <v-form
    ref="form"
    v-model="form"
  >
    <v-dialog
      v-model="createOrUpdateDialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ edit ? 'Uredi' : 'Dodaj novo' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="formData.notificationCategory"
                  :items="filters"
                  :rules="[rules.required]"
                  validate-in-submit
                  item-value="id"
                  item-text="name"
                  label="Gradska četvrt ili subjekt dojave"
                  clearable
                  required
                  single-line
                />
                <v-text-field
                  v-model="formData.email"
                  :rules="[rules.required, rules.email]"
                  clearable
                  label="Email"
                  required
                />
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="formData.sendAt"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      ref="sendAt"
                      v-model="formData.sendAt"
                      :rules="[rules.requiredIfNotInstantly]"
                      validate-in-input
                      label="Vrijeme slanja"
                      :disabled="!!formData.instantly"
                      readonly
                      clearable
                      v-on="on"
                    />
                  </template>
                  <v-time-picker
                    v-if="menu"
                    v-model="formData.sendAt"
                    format="24hr"
                    @click:minute="$refs.menu.save(formData.sendAt)"
                  />
                </v-menu>
                <v-switch
                  v-model="formData.instantly"
                  label="Odmah"
                  color="green"
                  value="true"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Odustani
          </v-btn>
          <v-btn
            class="ml-5"
            color="primary"
            @click="saveOrUpdate"
          >
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import { api } from '@/global/services/api'
export default {
  name: 'PatrolSubscriptionSettingsUpsert',

  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    },
    editItem: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      createOrUpdateDialog: false,
      edit: false,
      form: false,
      formData: {
        id: null,
        notificationCategory: '',
        email: '',
        sendAt: null,
        instantly: false
      },
      menu: false,
      rules: {
        required: value => !!value || 'Obavezno polje',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Upišite ispravnu email adresu'
        },
        requiredIfNotInstantly: value => {
          if (!this.formData.instantly) {
            return !!value || 'Odaberite vrijeme'
          }
          return true
        }
      }
    }
  },

  watch: {
    openDialog: {
      async handler (item) {
        if (this.editItem) {
          const response = await api()['road-maintenance'].get('patrol-subscription/' + this.editItem)
          this.edit = true
          this.formData.id = response.data.id
          this.formData.notificationCategory = response.data.notificationCategory
          this.formData.email = response.data.email
          if (response.data.sendAt) {
            this.formData.sendAt = response.data.sendAt
            this.formData.instantly = null
          }
          else {
            this.formData.sendAt = null
            this.formData.instantly = 'true'
          }
        }
        this.createOrUpdateDialog = item
      }
    },
    'formData.instantly': {
      handler (item) {
        if (item) {
          this.formData.sendAt = null
          this.$refs.sendAt?.resetValidation()
        }
      }
    }
  },

  methods: {
    async saveOrUpdate () {
      if (this.$refs.form.validate()) {
        const params = {
          notificationCategory: this.formData.notificationCategory,
          email: this.formData.email,
          sendAt: !this.formData.instantly && this.formData.sendAt ? this.formData.sendAt : null
        }
        if (this.edit) {
          await api()['road-maintenance'].patch('/patrol-subscription/' + this.formData.id, params)
        }
        else {
          await api()['road-maintenance'].post('/patrol-subscription', params)
        }
        this.closeDialog()
        this.$emit('pull-data')
      }
    },
    closeDialog () {
      this.formData = {
        id: null,
        notificationCategory: '',
        email: '',
        sendAt: null,
        instantly: false
      }
      this.edit = false
      this.$refs.form.reset()
      this.createOrUpdateDialog = false
      this.$emit('closed-dialog')
    }
  }
}
</script>
