<template>
  <div class="fill-height">
    <v-alert
      v-if="editMode"
      type="info"
      class="mb-0 py-3 rounded-0"
      v-text="$t('road-maintenance/tasks.help_text')"
    />
    <div class="locations-wrapper">
      <div class="data-table-container">
        <index-view
          view-name="tasks"
          row-pointer
          :module="module"
          :model="model"
          :list="list"
          :show-resource-route-name="'TaskView'"
          :pagination="pagination"
          :view-config="viewConfig"
          :fetch="fetch"
          :show-edit-dialog="showEditDialog"
          :reset-selection="resetSelection"
          :show-overlay="editMode"
          selectable-items
          @selection-updated="handleLocationSelection"
          @open-modal="isStreetSearchOpened = true"
        />
      </div>
      <street-search
        :open-dialog="isStreetSearchOpened"
        @dialog-closed="isStreetSearchOpened = false"
        @show-search-on-map="handleLocationSelection"
      />
      <div class="data-table-container">
        <index-view
          view-name="tasks"
          :module="'road-maintenance'"
          :model="'task-street'"
          :list="list"
          :pagination="pagination"
          :view-config="streetViewConfig"
          :fetch="fetch"
          :additional-filters="additionalFilters"
          :additional-form-data="additionalFormData"
          :show-edit-dialog="showEditDialogStreet"
          :reset-selection="resetSelection"
          :reset-form="resetForm"
          :container-classes="containerClasses"
          :show-overlay="editMode"
          hidden="true"
          @form-saved="handleFormSave"
          @close-edit="showEditDialogStreet = false"
          selectable-items
        />
      </div>
      <div
        class="map-container"
      >
        <locations-map
          class="locations-map fill-height"
          :edit-data="editLocationData"
          :edit-mode="editMode"
          :selected-locations="selectedLocations"
          :location-type="locationType"
          :create-btn-text="streetViewConfig.create_button"
          :view-config="streetViewConfig"
          @input="handleMapDataChange"
          @cancel="handleCancel"
          @create="handleCreate"
          @save="handleSave"
          @update-type="dataType = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'
import LocationsMap from '../components/LocationsMap'
import StreetSearch from '../components/StreetSearch.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/tasks')
export default {
  name: 'TasksIndexView',
  components: {
    LocationsMap,
    'index-view': indexView,
    StreetSearch
  },
  props: {
    module: {
      type: String,
      default: 'road-maintenance'
    },
    model: {
      type: String,
      default: 'tasks'
    }
  },
  data () {
    return {
      selectedLocations: [],
      dataType: null,
      locationData: null,
      editLocationData: null,
      showEditDialog: false,
      showEditDialogStreet: false,
      resetSelection: false,
      resetForm: false,
      editMode: false,
      isStreetSearchOpened: false
    }
  },
  computed: {
    ...mapGetters(['streetViewConfig', 'list', 'pagination', 'viewConfig']),

    locationType () {
      const routeType = this.$route.path.split('/locations/')[1]
      const locationTypeMap = {
        city: 1,
        region: 2,
        country: 3,
        user_defined: 4
      }

      return locationTypeMap[routeType]
    },

    additionalFilters () {
      return {
        type_id: this.locationType
      }
    },

    additionalFormData () {
      return {
        polyline: this.locationData,
        data_type_id: this.dataType
      }
    },

    containerClasses () {
      return ['pt-0', 'pr-0']
    }
  },
  methods: {
    ...mapActions(['fetch']),

    handleLocationSelection (locations) {
      if (Array.isArray(locations) && locations.length !== 1) {
        this.locationData = null
      }
      if (locations.length && this.editMode) {
        this.editMode = false
      }
      this.selectedLocations = locations
      this.resetSelection = false
    },

    handleMapDataChange (data) {
      if (this.selectedLocations.length !== 1 && !this.editMode) {
        this.locationData = null
      }
      else this.locationData = data
    },

    handleEdit (item) {
      this.locationData = null
      this.editLocationData = item
      this.editMode = true
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      this.resetSelection = true
    },

    handleCreate () {
      this.locationData = null
      this.editMode = true
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      this.resetSelection = true
    },

    handleFormSave () {
      this.editMode = false
      this.editLocationData = null
      this.showEditDialog = false
      this.showEditDialogStreet = false
    },

    handleSave () {
      if (!this.locationData) {
        return
      }

      this.showEditDialog = false
      this.showEditDialogStreet = true
      this.editMode = false
    },

    handleCancel () {
      this.dataType = null
      this.locationData = null
      this.editLocationData = null
      this.editMode = false
      this.showEditDialog = false
      this.showEditDialogStreet = false
      this.selectedLocations = []
      this.resetSelection = true
      this.resetFormData()
    },

    resetFormData () {
      this.resetForm = true

      setTimeout(() => {
        this.resetForm = false
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.locations-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 auto;
  padding: 1rem 0 0 0;
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  .map-container {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 1rem 1rem 1rem;
    height: calc(100vh - 125px);
    .locations-map {
      height: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .locations-wrapper {
    flex-direction: column;
    .data-table-container {
      padding-right: 1rem;
    }
    .map-container {
      .locations-map {
        height: 30rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .company-scope {
    flex-basis: unset;
    max-width: 45%;
  }
}
</style>
