<template>
  <v-dialog v-model="dialog" persistent fullscreen>
      <v-card class="modal-card">
        <v-card-title class="modal-header">Odabir lokacije</v-card-title>
        <v-card-text class="modal-content" style="padding: 0; margin: 0;">
                  <v-lazy style="height: 100%; padding: 0; margin: 0;">
                    <div style="height: 100%; padding: 0; margin: 0;">
                      <map-base
                        ref="mapBase"
                        :invalidateMapSize="invalidateMapSize"
                        @handleDragStartEvent="disableNavigator"
                        @handleClickEvent="handleMapClick"
                        @handleMarkerDragEnd="onMarkerDrag"
                      />
                    </div>
                  </v-lazy>
        </v-card-text>
        <v-card-actions class="modal-footer">
          <v-row>
            <v-col>
              <v-btn
                color="blue-grey darken-1"
                dark
                style="width: 95%; height: 3rem"
                @click="closeDialog"
              >
                {{ $t('base.close') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="blue-grey darken-1"
                dark
                style="width: 95%; height: 3rem"
                @click="confirmLocation"
              >
                {{ $t('base/patrol-vehicle.tips_form_location_modal_confirm_button') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
import MapBase from '@/global/components/map/MapBase.vue'
import { api } from '@/global/services/api'
export default {
  name: 'LocationMapModal',

  components: {
    MapBase
  },

  props: {
    latlng: {
      type: Array,
      default: () => []
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    locationDialog: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      dialog: false,
      navigatorWatcherId: 0,
      resolvedAddress: '',
      invalidateMapSize: false,
      marker: []
    }
  },

  watch: {
    latlng: function (val) {
      if (val.length > 0 && val[0] && val[1] && val[0] !== 0 && val[1] !== 0) {
        const that = this
        this.setLatLngForMarker(val[0], val[1])
        // Timeout is needed because marker is not visible on map without it
        setTimeout(() => {
          const markersConfig = {
            markers: that.marker,
            fitBoundsOptions: {
              maxZoom: 16
            }
          }
          this.generateMarkers(markersConfig)
        }, 50)
      }
    },
    locationDialog: function (locationDialog) {
      this.invalidateMapSize = locationDialog
      this.dialog = locationDialog
      if (this.dialog && !this.isEditing && this.latlng[0] === 0 && this.latlng[1] === 0) {
        // When dialog is being opened then fetch current user location
        this.fetchUserLocation()
      }
    }
  },

  emits: ['close-dialog', 'clicked-address', 'resolved-address'],

  methods: {
    // Disable navigator.geolocation.watchPosition
    disableNavigator () {
      if (this.navigatorWatcherId !== null) {
        navigator.geolocation.clearWatch(this.navigatorWatcherId)
        this.navigatorWatcherId = null
      }
    },

    async fetchUserLocation () {
      if (navigator.geolocation) {
        const options = {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }

        this.navigatorWatcherId = navigator.geolocation.watchPosition(
          position => {
            const { latitude, longitude } = position.coords
            this.setLatLngForMarker(latitude, longitude)
            const markersConfig = {
              markers: this.marker,
              fitBoundsOptions: {
                maxZoom: 16
              }
            }
            this.generateMarkers(markersConfig)
          },
          error => {
            console.error('Error getting user location:', error)
          },
          options
        )
      }
      else {
        console.error('Geolocation is not available.')
      }
    },

    // Helper function for updating marker coordinates
    setLatLngForMarker (lat, lng) {
      this.marker = [{
        id: 1,
        lat: lat,
        lon: lng,
        draggable: true,
        drag: (e) => {
          this.onMarkerDrag(e)
        }
      }]
    },

    onMarkerDrag (e) {
      // Update markers coordinates
      this.setLatLngForMarker(e.target._latlng.lat, e.target._latlng.lng)
    },

    handleMapClick (event) {
      const clickedLatLng = event.latlng
      this.disableNavigator()

      // Update markers coordinates
      this.setLatLngForMarker(clickedLatLng.lat, clickedLatLng.lng)
      const markersConfig = {
        markers: this.marker,
        fitBoundsOptions: {
          maxZoom: 16
        },
        fitMarkers: false
      }
      this.generateMarkers(markersConfig)
    },

    generateMarkers (markersConfig) {
      this.$refs?.mapBase?.onMapReady(function (map) {
        map.generateMarkers(markersConfig)
      })
    },

    // Geo reverse provided marker coordinates
    async resolveAddress (marker) {
      const params = {
        lat: marker.lat,
        lng: marker.lon
      }
      try {
        const { data } = await api()['satellite-tracking'].get('reverse-geocode', params)
        if (data && data.address) {
          this.resolvedAddress = data.address
        }
      }
      catch (error) {
        console.error('Error while resolving address in patrol vehicle service: ', error)
      }
    },

    closeDialog () {
      this.disableNavigator()
      this.marker = []
      // Restore map's center position
      this.$refs?.mapBase?.setCenteringPositions()
      // Remove all markers from map
      this.$refs?.mapBase?.removeMarkers()
      this.$emit('close-dialog')
    },

    async confirmLocation () {
      this.disableNavigator()

      if (this.marker.length && this.marker[0] && this.marker[0].lat && this.marker[0].lon) {
        await this.resolveAddress(this.marker[0])
      }

      // Emit resolved address to the parent component that will be displayable in input field as a string
      this.$emit('resolved-address', this.resolvedAddress)
      // Emit marker coordinates that are needed for creating/updating work order
      this.$emit('clicked-address', this.marker)
      // Emit close dialog event to parent component because opening/closing actions for this component are controlled by parent component

      const formData = new FormData()
      formData.append('latitude', this.marker[0].lat)
      formData.append('longitude', this.marker[0].lon)
      const response = await api()['road-maintenance'].post('check-is-coordinate-inside-city-district', formData)
      if (response.inside_polygon && response.location_id) {
        this.$emit('set-city-district-and-subject-type', response)
      }

      this.$emit('close-dialog')

      // Restore map's center position
      this.$refs?.mapBase?.setCenteringPositions()

      // Remove all markers from map
      this.$refs?.mapBase?.removeMarkers()

      // Clear markers array
      this.marker = []
    }
  }
}
</script>
<style scoped>
.modal-card {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
}

.modal-header {
  background-color: transparent;
  padding: 16px;
}

.modal-content {
  flex: 1;
  overflow: hidden;
}

.modal-footer {
  background-color: transparent;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}
</style>
