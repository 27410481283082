<template>
  <div
    class="overflow-y-hidden"
    :style="wrapperStyles"
  >
    <splitpanes
      horizontal
      :push-other-panes="false"
      class="default-theme h-full"
    >
      <pane
        :size="90"
        max-size="90"
        min-size="90"
      >
        <!-- lazy because of problems with vuetify and vue-leaflet https://github.com/vue-leaflet/Vue2Leaflet/issues/96 -->
        <v-lazy class="h-full">
          <!-- Important to have wrapping div when using `v-lazy`, otherwise map layout will be broken -->
          <div class="h-full">
            <traffic-lights-map
              ref="excavationOrderMap"
              class="h-full"
              :invoke-get-icons="invokeGetIcons"
              :permissions="permissions"
              :options="options"
              @reset-invoke-get-icons="value => invokeGetIcons = false"
            />
          </div>
        </v-lazy>
      </pane>
      <pane
        :size="10"
        min-size="10"
        max-size="10"
        class="overflow-y-auto"
      >
        <v-container fluid>
          <v-card>
            <v-card-text>
              <traffic-lights-controls
                :options.sync="options"
                :open-edit-dialog="openEditDialog"
                :permissions="permissions"
                @pull-data="invokeGetIcons = true"
                @get-options="getOptions"
              />
            </v-card-text>
          </v-card>
        </v-container>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import TrafficLightsMap from '@/modules/road-maintenance-module/traffic-lights/components/TrafficLightsMap.vue'
import TrafficLightsControls from '@/modules/road-maintenance-module/traffic-lights/components/TrafficLightsControls.vue'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/traffic-lights')
export default {
  name: 'TrafficLightsIndex',

  components: {
    TrafficLightsMap,
    TrafficLightsControls,
    Splitpanes,
    Pane
  },

  data () {
    return {
      invokeGetIcons: false,
      options: {
        trafficLightType: ''
      },
      openEditDialog: false
    }
  },

  computed: {
    ...mapGetters(['permissions']),
    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    }
  },

  async created () {
    await this.fetchPermissions()
  },

  methods: {
    ...mapActions(['fetchPermissions']),

    getOptions (val) {
      this.options.trafficLightType = val.trafficLightTypes.join(',')
      this.invokeGetIcons = true
    }
  }
}
</script>
