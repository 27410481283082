<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    :mapOptions="mapOptions"
  >
    <template #mapContent>
      <l-control
        v-show="!editMode"
        position="topright"
      >
        <v-btn
          v-if="!editMode && canCreate"
          color="primary"
          @click="$emit('create')"
          v-text="createBtnText"
        />
      </l-control>
      <l-control
        v-show="showSaveButton"
        position="topright"
      >
        <v-btn
          v-if="!editingShape"
          small
          color="primary"
          class="mr-2"
          @click="$emit('save')"
          v-text="$t('base.save')"
        />
        <v-btn
          small
          color="secondary"
          @click="$emit('cancel')"
          v-text="$t('base.cancel')"
        />
      </l-control>
    </template>
  </map-base>
</template>

<script>
import store from '@/global/store'
import L from 'leaflet'
import { LControl } from 'vue2-leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-draw'
import polyUtil from 'polyline-encoded'
import MapBase from '@/global/components/map/MapBase.vue'
import { isEmpty } from 'lodash'

export default {
  name: 'LocationsMap',

  components: {
    LControl,
    MapBase
  },

  props: {
    selectedLocations: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
    locationType: {
      type: Number,
      default: 0
    },
    createBtnText: {
      type: String,
      default: ''
    },
    viewConfig: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      mapOptions: {
        zoomSnap: 0.5
      },
      editableLayers: null,
      showSaveButton: false,
      editingShape: false,
      maxPolyPoints: 300,
      mapStyle: {
        width: '100%',
        height: '100%',
        zIndex: 0
      }
    }
  },

  computed: {
    canCreate () {
      if ('actions' in this.viewConfig) {
        return 'create' in this.viewConfig.actions
      }
      return false
    },

    drawLocal () {
      return {
        draw: {
          toolbar: {
            actions: {
              // title: 'Cancel drawing',
              text: this.$t('locations/map.cancel')
            },
            finish: {
              // title: 'Finish drawing',
              text: this.$t('locations/map.finish')
            },
            undo: {
              // title: 'Delete last point drawn',
              text: this.$t('locations/map.delete_last_point')
            },
            buttons: {
              polyline: this.$t('locations/map.polyline'),
              polygon: this.$t('locations/map.polygon'),
              rectangle: this.$t('locations/map.rectangle'),
              circle: this.$t('locations/map.circle'),
              marker: this.$t('locations/map.marker'),
              circlemarker: this.$t('locations/map.circlemarker')
            }
          },
          handlers: {
            circle: {
              tooltip: {
                start: this.$t('locations/map.circle_tooltip_start')
              },
              radius: this.$t('locations/map.radius')
            },
            circlemarker: {
              tooltip: {
                start: this.$t('locations/map.circlemarker_tooltip_start')
              }
            },
            marker: {
              tooltip: {
                start: this.$t('locations/map.marker_tooltip_start')
              }
            },
            polygon: {
              tooltip: {
                start: this.$t('locations/map.polygon_tooltip_start'),
                cont: this.$t('locations/map.polygon_tooltip_cont'),
                end: this.$t('locations/map.polygon_tooltip_end')
              }
            },
            polyline: {
              error: this.$t('locations/map.polyline_error'),
              tooltip: {
                start: this.$t('locations/map.polyline_tooltip_start'),
                cont: this.$t('locations/map.polyline_tooltip_cont'),
                end: this.$t('locations/map.polyline_tooltip_end')
              }
            },
            rectangle: {
              tooltip: {
                start: this.$t('locations/map.rectangle_tooltip_start')
              }
            },
            simpleshape: {
              tooltip: {
                end: this.$t('locations/map.simpleshape_tooltip_end')
              }
            }
          }
        },
        edit: {
          toolbar: {
            actions: {
              save: {
                // title: 'Save changes',
                text: this.$t('locations/map.save')
              },
              cancel: {
                // title: 'Cancel editing, discards all changes',
                text: this.$t('locations/map.cancel')
              },
              clearAll: {
                // title: 'Clear all layers',
                text: this.$t('locations/map.clear_all')
              }
            },
            buttons: {
              edit: this.$t('locations/map.edit_layers'),
              editDisabled: this.$t('locations/map.no_edit_layers'),
              remove: this.$t('locations/map.delete_layers'),
              removeDisabled: this.$t('locations/map.no_delete_layers')
            }
          },
          handlers: {
            edit: {
              tooltip: {
                text: this.$t('locations/map.edit_tooltip_text'),
                subtext: this.$t('locations/map.edit_tooltip_subtext')
              }
            },
            remove: {
              tooltip: {
                text: this.$t('locations/map.remove_tooltip_text')
              }
            }
          }
        }
      }
    },
    drawControlFull () {
      return new window.L.Control.Draw({
        position: 'topright',
        edit: {
          featureGroup: this.editableLayers,
          poly: {
            allowIntersection: false
          }
        },
        draw: {
          polyline: this.polylineDrawSettings(),
          polygon: false,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false
        }
      })
    },
    drawControlEditOnly () {
      return new window.L.Control.Draw({
        position: 'topright',
        edit: {
          featureGroup: this.editableLayers,
          poly: {
            allowIntersection: false
          }
        },
        draw: false
      })
    }
  },

  watch: {
    selectedLocations (locations) {
      const that = this
      if (this.editableLayers && locations.length) {
        this.editableLayers.clearLayers()
        this.removeDrawControls()
        this.showSaveButton = false
      }
      else {
        this.editableLayers.clearLayers()
      }
      locations.forEach(location => {
        location.streets.forEach(street => {
          street.polyline.includes('CIRCLE')
            ? this.handleCircleData(street)
            : this.handleOtherShapes(street, location.color)
        })
      })
      setTimeout(() => {
        if (that.editableLayers && !isEmpty(that.editableLayers.getBounds()) && that.editableLayers.getBounds() instanceof L.LatLngBounds) {
          that.$refs?.mapBase?.$refs?.map?.mapObject?.fitBounds(that.editableLayers.getBounds(), { padding: [5, 5] })
        }
      }, 150)
    },
    editMode (value) {
      if (value) {
        // issue when map is dragged library adds point and this prevents adding point when dragging
        // https://github.com/Leaflet/Leaflet.draw/issues/695#issuecomment-281663243
        var handler = window.L.Draw.Polyline.prototype._onTouch
        window.L.Draw.Polyline.prototype._onTouch = function (e) {
          if (e.originalEvent.pointerType !== 'mouse') {
            return handler(this, e)
          }
        }
        this.$refs?.mapBase?.$refs.map?.mapObject?.addControl(this.drawControlFull)

        if (this.editableLayers) {
          this.editableLayers.clearLayers()
          this.addFullDrawControls()
        }
      }
      else {
        if (this.editableLayers) {
          this.editableLayers.clearLayers()
          this.removeDrawControls()
        }
      }

      this.showSaveButton = value
    },
    editData (newValue) {
      if (newValue) {
        this.editableLayers.clearLayers()

        newValue.data.includes('CIRCLE')
          ? this.handleCircleData(newValue)
          : this.handleOtherShapes(newValue)

        this.activateEditControls()

        setTimeout(() => {
          this.$refs?.mapBase?.$refs?.map?.mapObject?.fitBounds(this.editableLayers.getBounds(), { padding: [5, 5] })
        }, 150)
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.initMapDrawControls()
      this.fixMissingIcons()
    })
  },

  methods: {
    polylineDrawSettings () {
      return {
        allowIntersection: false,
        showArea: true
      }
    },

    emitInputEvent (layer) {
      if (layer instanceof window.L.Circle) {
        const { lat, lng } = layer.getLatLng()
        const radius = layer.getRadius()
        const circleData = `CIRCLE (${lat} ${lng}, ${radius})`
        this.$emit('input', circleData)
      }
      else {
        window.L.geoJSON(layer.toGeoJSON(), {
          coordsToLatLng: function (coords) {
            // latitude , longitude, altitude
            return new window.L.LatLng(coords[0], coords[1], coords[2])
          },
          onEachFeature: (feature, layer) => {
            this.$emit('input', polyUtil.encode(layer.toGeoJSON().geometry.coordinates))
          }
        })
      }
    },

    updateDataType (layer) {
      let dataType = null

      if (layer instanceof window.L.Polyline) {
        dataType = 2
      }
      if (layer instanceof window.L.Polygon) {
        dataType = 1
      }
      if (layer instanceof window.L.Circle) {
        dataType = 3
      }
      if (layer instanceof window.L.Marker) {
        dataType = 4
      }

      this.$emit('update-type', dataType)
    },

    handleCircleData (location) {
      const [latLongPart, radiusPart] = location.data.split(',')

      const coordinates = latLongPart.replace('CIRCLE', '')
        .replace('(', '')
        .trim()
        .split(' ')
        .map(value => parseFloat(value))

      const radius = parseFloat(radiusPart.replace(')', '').trim())

      let circle = null
      let marker = null

      // Check if location type is point
      if (location.data_type.id === 4) {
        marker = new window.L.Marker(coordinates)
      }

      if (location.data_type.id !== 4 || !this.editMode) {
        circle = new window.L.Circle(coordinates, { radius })
        circle.setStyle({ color: location.color })
      }

      setTimeout(() => {
        if (circle) {
          this.editableLayers.addLayer(circle)
          this.updateDataType(circle)
          this.emitInputEvent(circle)
        }
        if (marker) {
          this.editableLayers.addLayer(marker)
          this.updateDataType(marker)
          this.emitInputEvent(marker)
        }
      }, 100)
    },

    handleOtherShapes (location, color) {
      const geoJsonData = {
        type: 'LineString',
        coordinates: polyUtil.decode(location.polyline)
      }
      window.L.geoJson(geoJsonData, {
        coordsToLatLng: function (coords) {
          // latitude , longitude, altitude
          return new window.L.LatLng(coords[0], coords[1], coords[2])
        },
        onEachFeature: (feature, layer) => {
          if (!(layer instanceof window.L.Marker)) {
            layer.setStyle({ color: color })
            layer.bindPopup(
              'Zadatak: ' + (location.task ? location.task.name : '') + '<br>' +
              'Ulica: ' + location.street_name + '<br>' +
              'Ekipa: ' + location.winter_service_team + '<br>' +
              'Duljina: ' + location.length + ' m' + '<br>' +
              'Širina: ' + location.width + ' m' + '<br>' +
              'Broj prolaza: ' + location.passes + '<br>' +
              'Površina čišćenja: ' + location.area + ' m2'
            )
            layer.on('mouseover', function (e) {
              this.openPopup(e.latlng)
            })
            layer.on('mouseout', function (e) {
              this.closePopup()
            })
          }
          this.editableLayers.addLayer(layer)
          this.updateDataType(layer)
          this.emitInputEvent(layer)
        }
      })
    },

    limitPolylinePoints (layer, layerType) {
      // Only limit number of points for polyline and polygon shape types
      if (layerType === 'polyline' || layerType === 'polygon') {
        const layerLatLngs = layerType === 'polygon'
          ? layer.getLatLngs()[0]
          : layer.getLatLngs()

        if (layerLatLngs.length > this.maxPolyPoints) {
          // Create and enable draw handler, needed for deleting last vertex functionality
          const drawPolyline = new window.L.Draw.Polyline(this.$refs?.mapBase?.$refs?.map?.mapObject, this.drawControlFull._toolbars.draw.options.polyline)
          drawPolyline.enable()

          // Remove extra vertices from the shape
          const extraVertices = layerLatLngs.splice(this.maxPolyPoints)
          extraVertices.forEach(() => {
            drawPolyline.deleteLastVertex()
          })

          // Complete the shape (for polyline)
          drawPolyline.completeShape()

          // Disable draw handler
          drawPolyline.disable()

          // Redraw the shape on the map (needed for edit functionality)
          layer.redraw()

          // Show warning message
          store.dispatch('base/notifications/push', this.$t('locations/map,limit_polyline').replace(':limit', this.maxPolyPoints))

          return true
        }
      }

      return false
    },

    getLayerType (layer) {
      if (layer instanceof window.L.Circle) {
        return 'circle'
      }

      if (layer instanceof window.L.Marker) {
        return 'marker'
      }

      if ((layer instanceof window.L.Polyline) && !(layer instanceof window.L.Polygon)) {
        return 'polyline'
      }

      if ((layer instanceof window.L.Polygon) && !(layer instanceof window.L.Rectangle)) {
        return 'polygon'
      }

      if (layer instanceof window.L.Rectangle) {
        return 'rectangle'
      }
    },

    initMapDrawControls () {
      const map = this.$refs?.mapBase?.$refs?.map?.mapObject
      this.editableLayers = new window.L.FeatureGroup()
      this.editableLayers.addTo(map)

      window.L.drawLocal = this.drawLocal

      map.on(window.L.Draw.Event.CREATED, (e) => {
        const layer = e.layer
        const layerType = e.layerType

        this.limitPolylinePoints(layer, layerType)

        this.updateDataType(layer)
        this.emitInputEvent(layer)

        this.editableLayers.addLayer(layer)
        this.activateEditControls()
      })

      map.on(window.L.Draw.Event.EDITED, (e) => {
        let shapeChanged = false

        e.layers.eachLayer(layer => {
          const layerType = this.getLayerType(layer)

          shapeChanged = this.limitPolylinePoints(layer, layerType)

          this.updateDataType(layer)
          this.emitInputEvent(layer)
        })

        if (!shapeChanged) {
          this.$nextTick(() => this.$emit('save'))
        }
      })

      map.on(window.L.Draw.Event.EDITSTART, (e) => {
        this.editingShape = true
      })

      map.on(window.L.Draw.Event.EDITSTOP, (e) => {
        this.editingShape = false
      })

      map.on(window.L.Draw.Event.DELETED, (e) => {
        this.$emit('input', null)
        this.addFullDrawControls()
      })
    },

    fixMissingIcons () {
      delete window.L.Icon.Default.prototype._getIconUrl

      window.L.Icon.Default.mergeOptions({
        iconRetinaUrl: '/img/icons/icon_sensor_start.svg',
        iconUrl: '/img/icons/icon_sensor_start.svg',
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      })
    },

    activateEditControls () {
      const map = this.$refs?.mapBase?.$refs?.map?.mapObject
      this.drawControlFull.remove(map)
      this.drawControlEditOnly.addTo(map)
    },

    addFullDrawControls () {
      const map = this.$refs?.mapBase?.$refs?.map?.mapObject

      if (this.editableLayers.getLayers().length === 0) {
        this.drawControlEditOnly.remove(map)
        this.drawControlFull.addTo(map)
        this.removeTitleFromControls()
      }
    },

    removeDrawControls () {
      const map = this.$refs?.mapBase?.$refs?.map?.mapObject
      this.drawControlFull.remove(map)
      this.drawControlEditOnly.remove(map)
    },

    removeTitleFromControls () {
      document.querySelectorAll('.leaflet-draw a:not(.leaflet-disabled)').forEach(element => {
        element.removeAttribute('title')
      })
    }
  }
}
</script>

<style>
.leaflet-touch .leaflet-bar a {
  position: relative;
}

.leaflet-draw a .sr-only {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  padding: 5px 10px;
  margin: 0;
  z-index: 10;
  transform: translateX(calc(-100% - 5px)) translateY(3px);
  background: #fff;
  color: #333;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  clip: unset;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.leaflet-draw a.leaflet-disabled .sr-only,
.leaflet-draw a.leaflet-draw-toolbar-button-enabled .sr-only {
  visibility: hidden;
}
</style>
