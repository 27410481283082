<template>
  <div>
    <div v-for="(attribute, index) in tab.columns" :key="index">
      <div v-if="tab.label === 'Sanacija'">
        <v-row v-if="!attribute.options || attribute.options !== 'completed'">
          <v-col>
            <v-menu
              v-model="datePickerMenus[index]"
              transition="slide-y-transition"
              min-width="auto"
              :close-on-content-click="false"
            >
              <template #activator="{}">
                <div
                  v-if="attribute.type === 'datepicker'"
                  class="clickable-text-field"
                  style="margin-bottom: 1.7rem;"
                >
                  <v-text-field
                    v-if="attribute.type === 'datepicker'"
                    v-model="formDataCopy[index]"
                    :class="{ 'invalid-date-text-color': isInvalidDateCopy }"
                    append-icon="mdi-calendar-month-outline"
                    :label="attribute.label"
                    :error-messages="validationErrorsCopy[index]"
                    hide-details
                    filled
                    clearable
                    :readonly="!canEdit && tab.label !== 'Sanacija'"
                    @click:append="onDatePickerClick(index)"
                    @input.native="onDatePickerChange($event, index)"
                  />
                </div>
              </template>
              <v-date-picker
                v-model="formDataCopy['date_' + index]"
                first-day-of-week="1"
                :min="formatDate(minDate, index, 'min')"
                :max="formatDate(maxDate, index, 'max')"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="acceptChosenDate(index, canEdit)"
                >
                  {{ $t('base.ok') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="attribute.options === 'completed'">
          <v-col cols="3">
            <v-text-field
              v-if="attribute.type === 'text'"
              style="margin-bottom: 1rem;"
              v-model="formDataCopy[index]"
              :label="attribute.label"
              :type="attribute.type"
              readonly
              hide-details
              filled
              dense
            />
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-if="attribute.type === 'text'"
              v-model="formDataCopy['completed_' + index]"
              :label="attribute.label"
              :type="attribute.type"
              hide-details
              :readonly="!canEdit && tab.label !== 'Sanacija'"
              filled
              dense
            />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-text-field
          v-if="attribute.type === 'text'"
          v-model="formDataCopy[index]"
          :label="attribute.label"
          :type="attribute.type"
          filled
          :readonly="!canEdit && tab.label !== 'Primopredaja' && tab.label !== 'Sanacija'"
          :clearable="canEdit"
          dense
        />
        <v-combobox
          v-if="attribute.type === 'autocomplete'"
          v-model="formDataCopy[index]"
          :loading="isLoading"
          :items="searchResults"
          :label="attribute.label"
          :search-input.sync="search"
          item-text="label"
          :item-value="getCoordinates"
          hide-selected
          hide-no-data
          @change="onSelectedAddress"
          @input.native="onInput($event, index)"
          filled
          :readonly="!canEdit || disableAutocomplete"
          dense
          :clearable="canEdit"
        />
        <v-autocomplete
          v-if="attribute.type === 'dropdown' && attribute.parameter !== 'withTranslations'"
          v-model="formDataCopy[index]"
          :label="attribute.label"
          :items="dropDownValues[index]"
          :item-text="index === 'road_administration' ? 'road_administration_name' : 'name'"
          item-value="id"
          filled
          :readonly="!canEdit && tab.label !== 'Primopredaja'"
          :clearable="canEdit"
        >
          <template v-slot:item="{ item }" v-if="index === 'investor_responsible_person'">
            {{ item.name }} / {{ item.email }} / {{ item.phone }}
          </template>
          <template v-slot:selection="{ item }" v-if="index === 'investor_responsible_person'">
            {{ item.name }} / {{ item.email }} / {{ item.phone }}
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-if="attribute.type === 'dropdown' && attribute.parameter === 'withTranslations'"
          v-model="formDataCopy[index]"
          :label="attribute.label"
          :items="dropDownValues[index]"
          :item-text="item => $t(item.description)"
          item-value="id"
          filled
          :readonly="!canEdit"
          :clearable="canEdit"
        />
        <v-menu
          v-model="datePickerMenus[index]"
          transition="slide-y-transition"
          min-width="auto"
          :close-on-content-click="false"
        >
          <template #activator="{}">
            <div
              v-if="attribute.type === 'datepicker'"
              class="clickable-text-field"
            >
              <v-text-field
                v-if="attribute.type === 'datepicker'"
                v-model="formDataCopy[index]"
                :class="{ 'invalid-date-text-color': isInvalidDateCopy }"
                append-icon="mdi-calendar-month-outline"
                :label="attribute.label"
                :error-messages="validationErrorsCopy[index]"
                filled
                :clearable="canEdit"
                :readonly="!canEdit && tab.label !== 'Primopredaja'"
                @click:append="onDatePickerClick(index)"
                @input.native="onDatePickerChange($event, index)"
              />
            </div>
          </template>
          <v-date-picker
            v-model="formDataCopy['date_' + index]"
            first-day-of-week="1"
            :min="formatDate(minDate, index, 'min')"
            :max="formatDate(maxDate, index, 'max')"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="acceptChosenDate(index, canEdit)"
            >
              {{ $t('base.ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-checkbox
          v-if="attribute.type === 'checkbox'"
          v-model="formDataCopy[index]"
          :label="attribute.label"
          :readonly="!canEdit"
        />
        <v-btn
          v-if="attribute.type === 'modal' && canEdit"
          @click="openLocationModal(index)"
          class="mb-5"
        >
          {{ $t('road-maintenance.excavation_modal_location_btn') }}
        </v-btn>
        <v-text-field
          v-if="attribute.type === 'modal'"
          v-model="formDataCopy[index]"
          :label="attribute.label"
          filled
          dense
          readonly
        />
        <div
          v-if="attribute.type === 'display_images'"
          style="position: relative;"
        >
          <span
            v-if="attribute.type === 'display_images'"
            style="font-weight: bold; font-size: 1rem;"
          >
            Fotografije
          </span>
          <v-tooltip
            v-if="uploadDocumentsCopy.images.length < 3"
            color="black"
            top
          >
            <template
              #activator="{ on, attrs }"
            >
              <v-btn
                style="height: 1.5rem; margin-bottom: 0.4rem;"
                class="primary ml-2"
                v-bind="attrs"
                v-on="on"
                @click="openFileInput('imageInput')"
              >
                <input
                  ref="imageInput"
                  type="file"
                  enctype="multipart/form-data"
                  multiple
                  :accept="'image/*'"
                  :max="3"
                  hidden
                  @change="selectedImages($event)"
                >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>Učitaj fotografije</span>
          </v-tooltip>
          <div
            v-if="uploadDocumentsCopy.images.length"
            style="position: absolute; right: 0; margin-top: -34px;"
          >
            <v-btn
              class="error mr-3"
              style="color: white;"
              @click="uploadDocumentsCopy.images = []"
            >
              Ukloni sve
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <v-btn
              color="green"
              style="color: white;"
              @click="downloadAllImages"
            >
              Preuzmi sve
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
          <v-divider
            v-if="attribute.type === 'display_images'"
            class="mb-3"
          />
        </div>
        <div
          v-if="attribute.type === 'display_images'"
          class="image-row"
        >
          <div
            v-for="number in attribute.numberOfPlaceholders"
            :key="number"
          >
            <template
              v-if="number - 1 in uploadDocumentsCopy.images"
            >
              <!-- If uploaded image exists for this placeholder -->
              <v-img
                :src="uploadDocumentsCopy.images[number - 1].url"
                class="uploaded-image"
                alt="slika nedostupna"
              >
                <v-tooltip
                  color="gray"
                  top
                >
                  <template
                    #activator="{ on, attrs }"
                  >
                    <v-btn
                      small
                      class="primary"
                      style="position: absolute; top: 0; right: 0;"
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadImage(uploadDocumentsCopy.images[number - 1])"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Preuzmi</span>
                </v-tooltip>
                <v-tooltip
                  color="gray"
                  top
                >
                  <template
                    #activator="{ on, attrs }"
                  >
                    <v-btn
                      small
                      class="error"
                      style="position: absolute; top: 0; right: 35%;"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteImage(number - 1)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Obriši</span>
                </v-tooltip>
              </v-img>
            </template>
            <template v-else>
              <!-- Placeholder if no uploaded image for this index -->
              <div
                class="mb-5 mr-3"
                style="width: 10rem; height: 10rem; border: 0.2rem dashed; text-align: center; align-content: center;"
              >
                Fotografija
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="attribute.type === 'display_pdf_files'"
          class="mt-4"
          style="position: relative;"
        >
          <span
            v-if="attribute.type === 'display_pdf_files'"
            style="font-weight: bold; font-size: 1rem;"
          >
            Pdf dokumenti
          </span>
          <v-tooltip
            v-if="uploadDocumentsCopy.pdfFiles.length < maximumAllowedPdfFiles"
            color="black"
            top
          >
            <template
              #activator="{ on, attrs }"
            >
              <v-btn
                style="height: 1.5rem; margin-bottom: 0.4rem;"
                class="primary ml-2"
                v-bind="attrs"
                v-on="on"
                @click="openFileInput('pdfInput')"
              >
                <input
                  ref="pdfInput"
                  type="file"
                  enctype="multipart/form-data"
                  multiple
                  :accept="'application/pdf'"
                  :max="2"
                  hidden
                  @change="selectedPdfFiles($event)"
                >
                <v-icon
                  style="font-size: 1.5rem;"
                >
                  mdi-upload
                </v-icon>
              </v-btn>
            </template>
            <span>Učitaj pdf dokumenta</span>
          </v-tooltip>
          <div
            v-if="uploadDocumentsCopy.pdfFiles.length"
            style="position: absolute; right: 0; margin-top: -34px;"
          >
            <v-btn
              class="error mr-3"
              style="color: white;"
              @click="uploadDocumentsCopy.pdfFiles = []"
            >
              Ukloni sve
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <v-btn
              color="green"
              style="color: white;"
              @click="downloadAllPdfFiles"
            >
              Preuzmi sve
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
          <v-divider
            v-if="attribute.type === 'display_pdf_files'"
            class="mb-3"
          />
        </div>
        <div
          v-if="attribute.type === 'display_pdf_files'"
        >
          <div
            v-for="number in attribute.numberOfPlaceholders"
            :key="number"
          >
            <template
              v-if="number - 1 in uploadDocumentsCopy.pdfFiles"
            >
              <div
                style="position: relative; align-items: center; background: transparent;"
                class="mb-5 mt-5"
              >
                <v-tooltip
                  color="gray"
                  top
                >
                  <template
                    #activator="{ on, attrs }"
                  >
                    <div
                      class="pdf-info"
                      @click="downloadPdf(uploadDocumentsCopy.pdfFiles[number - 1])"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        style="font-size: 2rem;"
                        color="red"
                      >
                        mdi-file-pdf-box
                      </v-icon>
                      <span
                        style="color: black; font-weight: bold;"
                      >
                        {{ uploadDocumentsCopy.pdfFiles[number - 1].name }}
                      </span>
                    </div>
                  </template>
                  <span>Preuzmi</span>
                </v-tooltip>
                <v-tooltip
                  color="gray"
                  top
                >
                  <template
                    #activator="{ on, attrs }"
                  >
                    <v-icon
                      class="delete-pdf-icon"
                      v-bind="attrs"
                      v-on="on"
                      @click="deletePdf(number - 1)"
                    >
                      mdi-trash-can
                    </v-icon>
                  </template>
                  <span>Ukloni</span>
                </v-tooltip>
              </div>
            </template>
            <template
              v-else
            >
              <div
                class="mb-5 mr-3"
                style="width: 10rem; height: 3rem; border: 0.2rem dashed; text-align: center; align-content: center;"
              >
                PDF
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <LocationMapModal
      :location-dialog="showLocationModal"
      :editAddress="editCoordinates"
      :dialog-title="$t('base/patrol-vehicle.tips_form_location_modal_title')"
      :index="indexForMap"
      :mapClass="mapClass"
      @clicked-address="getClickedAddress"
      @close-dialog="closeLocationMapModal"
    />
  </div>
</template>

<script>
import LocationMapModal from './LocationMapModal.vue'
import { api } from '../../../../global/services/api'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { convertToBase64 } from '@/global/services/helpers/files'
const DATE_PICKER_FORMAT = 'YYYY-MM-DD'
export default {
  name: 'ExcavationTabFormFields',

  components: {
    LocationMapModal
  },

  props: {
    tab: {
      type: Object,
      required: true,
      default: () => {}
    },
    editAddress: {
      type: Object,
      default: () => {}
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    userInput: {
      type: String,
      default: ''
    },
    resetLocationModalOpening: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      required: true
    },
    confirmedCoordinates: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Object,
      required: true
    },
    isInvalidDate: {
      type: Boolean,
      required: true
    },
    dropDownValues: {
      type: [Array, Object],
      required: true,
      default: () => []
    },
    mapClass: {
      type: String,
      default: 'excavation-location-map-modal'
    },
    uploadDocuments: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      datePickerIndex: '',
      showLocationModal: false,
      indexForMap: '',
      minDate: new Date(),
      maxDate: new Date(),
      datePickerMenus: [],
      searchResults: [],
      search: null,
      searchTimeout: null,
      isLoading: false,
      editCoordinates: {},
      isInvalidDateCopy: false,
      disableAutocomplete: false,
      userInputCopy: '',
      maxSizePerImageInPixels: 1024,
      initialLoad: true,
      maximumAllowedPdfFiles: 2,
      maximumAllowedImages: 3,
      maximumMBPerResizedImage: 3
    }
  },

  computed: {
    formDataCopy: {
      get () {
        return this.formData
      },
      set (val) {
        return val
      }
    },
    uploadDocumentsCopy: {
      get () {
        return this.uploadDocuments
      },
      set (val) {
        return val
      }
    },
    validationErrorsCopy () {
      return this.validationErrors
    },
    confirmedCoordinatesCopy: {
      get () {
        return this.confirmedCoordinates
      },
      set (val) {
        return val
      }
    }
  },

  watch: {
    formDataCopy: {
      deep: true,
      handler (newData) {
        this.$emit('update-data', newData)
      }
    },
    uploadDocumentsCopy: {
      deep: true,
      handler (newDocuments) {
        this.$emit('update-documents', newDocuments)
      }
    },
    userInputCopy: {
      deep: true,
      handler (val) {
        this.$emit('update-user-input', val)
      }
    },
    resetLocationModalOpening: {
      deep: true,
      handler (val) {
        this.showLocationModal = false
      }
    },
    validationErrorsCopy: {
      deep: true,
      handler (newErrors) {
        this.$emit('update-errors', newErrors)
      }
    },
    isInvalidDateCopy: {
      handler (val) {
        this.$emit('update-is-invalid-date', val)
      }
    },
    confirmedCoordinatesCopy: {
      handler (val) {
        this.$emit('update-confirmed-coordinates', val)
      }
    },
    search: {
      handler (val) {
        if (!this.showLocationModal && !this.initialLoad) {
          this.isLoading = true
          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout)
          }

          this.searchTimeout = setTimeout(() => {
            this.searchResults = []
            if (val && val !== '') {
              this.searchAddress(val)
            }
            else {
              this.isLoading = false
            }
          }, 500)
        }
        else {
          this.initialLoad = false
        }
      }
    }
  },

  async created () {
    this.userInputCopy = this.userInput
  },

  methods: {
    isEmpty,

    isDateValid (date) {
      const datePattern = /^([1-9]|[12][0-9]|3[01])\/([1-9]|1[0-2])\/([1-9][0-9])$/
      return datePattern.test(date)
    },

    openFileInput (type) {
      this.$refs[type][0].click()
      this.$refs[type][0].value = ''
    },

    selectedImages (event) {
      try {
        // Close file dialog if any of these props are unset
        if (!event || !event.target || !event.target.files) {
          alert('Dogodila se pogreška prilikom učitavanja slika, molimo pokušajte ponovo')
          return
        }

        // If there is no selected files, close file dialog
        if (!event.target.files.length) {
          return
        }

        const selectedFiles = event.target.files
        const remainingSlots = this.maximumAllowedImages - this.uploadDocumentsCopy.images.length

        // Checking whether the number of uploaded files exceeds the limit
        if (selectedFiles.length > this.maximumAllowedImages || remainingSlots < selectedFiles.length) {
          alert('Možete učitati maksimalno 3 fotografije!')
          return
        }

        for (let i = 0; i < Math.min(selectedFiles.length, remainingSlots); i++) {
          // Resize uploaded image
          this.resizeImage(selectedFiles[i], this.maxSizePerImageInPixels).then(({ dataUrl, sizeInMB }) => {
            const imageName = selectedFiles[i].name

            // Get image name without extension
            const lastDotIndex = imageName.lastIndexOf('.')
            let imageNameWithoutExtension = imageName
            if (lastDotIndex !== -1) {
              imageNameWithoutExtension = imageName.substring(0, lastDotIndex)
            }

            // Checking whether the resized image size exceeds allowed size
            if (sizeInMB > this.maximumMBPerResizedImage) {
              alert(`Veličina slike: ${imageNameWithoutExtension} je prevelika, molimo odaberite sliku manje veličine`)
              return null
            }

            return {
              name: imageNameWithoutExtension,
              url: dataUrl,
              size: sizeInMB
            }
          }).then(imageData => {
            if (imageData) {
              this.uploadDocumentsCopy.images.push(imageData)
            }
          }).catch((error) => {
            console.error('Error occurred during resize images process, exception: ' + error)
          })
        }
      }
      catch (error) {
        alert('Dogodila se pogreška prilikom učitavanja slika, molimo pokušajte ponovo')
        console.error('Error occurred during upload images process, exception: ', error)
      }
    },

    resizeImage (file, maxSize) {
      const reader = new FileReader()
      const image = new Image()
      const canvas = document.createElement('canvas')

      const resize = () => {
        let { width, height } = image

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        }
        else if (height > maxSize) {
          width *= maxSize / height
          height = maxSize
        }

        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)

        const dataUrl = canvas.toDataURL('image/jpeg')
        const bytes = dataUrl.length * 0.75
        const megabytes = bytes / (1024 * 1024)

        return { dataUrl, sizeInMB: megabytes }
      }

      return new Promise((resolve, reject) => {
        if (!file.type.match(/image.*/)) {
          reject(new Error('Not an image'))
          return
        }

        reader.onload = (readerEvent) => {
          image.onload = () => {
            const { dataUrl, sizeInMB } = resize()
            resolve({ dataUrl, sizeInMB })
          }
          image.src = readerEvent.target.result
        }

        reader.readAsDataURL(file)
      })
    },

    async deleteImage (index) {
      this.$delete(this.uploadDocumentsCopy.images, index)
    },

    downloadImage (image) {
      const link = document.createElement('a')
      link.href = image.url
      link.download = image.name
      link.click()
    },

    selectedPdfFiles (event) {
      const pdfFiles = event.target.files

      if (pdfFiles.length && (pdfFiles.length > this.maximumAllowedPdfFiles || this.maximumAllowedPdfFiles - this.uploadDocumentsCopy.pdfFiles.length < pdfFiles.length)) {
        alert('Možete učitati maksimalno 2 pdf fajla!')
        return
      }

      for (let i = 0; i < pdfFiles.length; i++) {
        convertToBase64(pdfFiles[i]).then(base64Content => {
          this.uploadDocumentsCopy.pdfFiles.push({
            name: pdfFiles[i].name, // For frontend purpose
            content: base64Content
          })
        })
      }
    },

    deletePdf (index) {
      this.$delete(this.uploadDocumentsCopy.pdfFiles, index)
    },

    downloadPdf (pdfFile) {
      const downloadLink = document.createElement('a')
      downloadLink.href = pdfFile.content
      downloadLink.download = pdfFile.name
      downloadLink.click()
    },

    downloadAllImages () {
      if (this.uploadDocumentsCopy.images && this.uploadDocumentsCopy.images.length) {
        this.uploadDocumentsCopy.images.map(image => {
          const link = document.createElement('a')
          link.href = image.url
          link.download = image.name
          link.click()
        })
      }
    },

    downloadAllPdfFiles () {
      if (this.uploadDocumentsCopy.pdfFiles && this.uploadDocumentsCopy.pdfFiles.length) {
        this.uploadDocumentsCopy.pdfFiles.map(pdfFile => {
          const link = document.createElement('a')
          link.href = pdfFile.content
          link.download = pdfFile.name
          link.click()
        })
      }
    },

    onInput (event, index) {
      this.userInputCopy = event.target.value
      this.$set(this.formDataCopy, 'address', event.target.value)
    },
    onSelectedAddress (address) {
      const that = this
      if (this.formDataCopy.address && this.formDataCopy.address.label && this.userInputCopy !== this.formDataCopy.address.label) {
        this.userInputCopy = this.userInput
      }
      setTimeout(() => {
        if (that.userInputCopy !== '') {
          that.$set(that.formDataCopy, 'address', that.userInputCopy)
        }
      }, 100)
      if (address && address !== '' && address.x && address.y) {
        that.editCoordinates = address
        that.indexForMap = 'location'
        that.showLocationModal = true
      }
    },

    getCoordinates (item) {
      return { x: item.x, y: item.y, label: item.label }
    },

    helpDate (date) {
      if (date !== null && this.isDateValid(date)) {
        const parts = date.split('/')
        const year = dayjs().format('YYYY').slice(0, 2) + parts[2]
        const month = parts[1].length === 1 ? '0' + parts[1] : parts[1]
        const day = parts[0].length === 1 ? '0' + parts[0] : parts[0]
        return `${year}-${month}-${day}`
      }
    },

    async searchAddress (val) {
      try {
        this.searchResults = await api()['satellite-tracking'].get(`map-search?mapType=here&q=${val}`)
        this.isLoading = false
      }
      catch (e) {
        this.isLoading = false
        console.log(e)
      }
    },

    formatDate (date, index, maxMin) {
      if (maxMin === 'min') {
        if (index === 'work_completed_at' && this.formDataCopy.work_started_at) {
          return this.helpDate(this.formDataCopy.work_started_at)
        }
        if (index === 'completed_work_to' && this.formDataCopy.completed_work_from) {
          return this.helpDate(this.formDataCopy.completed_work_from)
        }
      }
      else {
        if (index === 'requested_at') {
          return dayjs(date).format('YYYY-MM-DD')
        }
        if (index === 'work_started_at' && this.formDataCopy.work_completed_at) {
          return this.helpDate(this.formDataCopy.work_completed_at)
        }
        if (index === 'completed_work_from' && this.formDataCopy.completed_work_to) {
          return this.helpDate(this.formDataCopy.completed_work_to)
        }
      }

      return null
    },
    openLocationModal (index) {
      if (this.formDataCopy.location && this.formDataCopy.location !== '') {
        this.editCoordinates = { y: this.formDataCopy.location.split(' ')[0], x: this.formDataCopy.location.split(' ')[1] }
      }
      this.$emit('update-location-modal-open-status', true)
      this.indexForMap = index
      this.showLocationModal = true
    },

    onDatePickerChange (event, index) {
      const value = event.target.value
      this.datePickerIndex = index
      if (value) {
        this.isInvalidDateCopy = !this.isDateValid(value)
        if (!this.isInvalidDateCopy) {
          delete this.validationErrorsCopy[index]
          this.formDataCopy[index] = value
        }
        else {
          this.validationErrorsCopy[index] = this.getDateTranslations(index) + ' nije ispravan.'
        }
      }
      else {
        delete this.validationErrorsCopy[index]
        this.isInvalidDateCopy = false
      }
    },

    onDatePickerClick (fieldKey) {
      if (!fieldKey || !this.formDataCopy[fieldKey]) {
        this.$set(this.datePickerMenus, fieldKey, true)
        return
      }

      // Get fields date format from field config
      const fieldDateFormat = this.getFieldsDateFormat(fieldKey)

      // Convert date format to datepicker known format (YYYY-MM-DD)
      const datePickerFormatted = dayjs(this.formDataCopy[fieldKey], fieldDateFormat).format(DATE_PICKER_FORMAT)

      // Set date value for datepicker
      this.$set(this.formDataCopy, 'date_' + fieldKey, datePickerFormatted)

      // Open date picker
      this.$set(this.datePickerMenus, fieldKey, true)
    },

    getFieldsDateFormat (fieldKey) {
      const { columns } = this.tab || {}
      let finalFormat = DATE_PICKER_FORMAT

      if (!fieldKey || !columns) {
        return finalFormat
      }

      if (columns[fieldKey]) {
        const { format } = columns[fieldKey]

        finalFormat = format && typeof format === 'string' ? format : DATE_PICKER_FORMAT
      }

      return finalFormat
    },

    getDateTranslations (dateField) {
      const translations = {
        requested_at: this.$t('road-maintenance/excavation-service/tabs/request/columns.request_date'),
        consented_at: this.$t('road-maintenance/excavation-service/tabs/approval/columns.consent_date'),
        work_started_at: this.$t('road-maintenance/excavation-service/tabs/approval/columns.start_date_of_work'),
        work_completed_at: this.$t('road-maintenance/excavation-service/tabs/approval/columns.end_date_of_work'),
        notification_at: this.$t('road-maintenance/excavation-service.notification_at'),
        handovered_at: this.$t('road-maintenance/excavation-service/tabs/handover/columns.handover_date'),
        completed_at: this.$t('road-maintenance/excavation-service/tabs/cleanup/columns.cleanup_date'),
        billed_at: this.$t('road-maintenance/excavation-service/tabs/invoice/columns.invoice_date')
      }
      return translations[dateField] ? translations[dateField] : dateField
    },

    acceptChosenDate (index, canEdit) {
      if (!canEdit && ['completed_at', 'handovered_at'].indexOf(index) === -1) {
        this.datePickerMenus[index] = false
        return
      }

      if (this.formDataCopy['date_' + index] && this.formDataCopy['date_' + index] !== '') {
        // Get fields date format from field config
        const fieldDateFormat = this.getFieldsDateFormat(index)
        this.isInvalidDateCopy = false
        delete this.validationErrors[index]
        this.formDataCopy[index] = dayjs(this.formDataCopy['date_' + index]).format(fieldDateFormat)
      }
      this.datePickerMenus[index] = false
    },

    closeLocationMapModal () {
      this.showLocationModal = false
      this.searchResults = []
      this.editCoordinates = {}
      this.$emit('update-location-modal-open-status', false)
    },

    async getClickedAddress (address) {
      if (this.indexForMap !== '' && address && address.length !== 0 && address[0].lat && address[0].lon) {
        this.$set(this.confirmedCoordinatesCopy, 'x', address[0]?.lon)
        this.$set(this.confirmedCoordinatesCopy, 'y', address[0]?.lat)
        this.$set(this.formDataCopy, this.indexForMap, `${address[0]?.lat} ${address[0]?.lon}`)
        const formData = new FormData()
        formData.append('latitude', address[0].lat)
        formData.append('longitude', address[0].lon)
        const response = await api()['road-maintenance'].post('check-is-coordinate-inside-city-district', formData)
        if (response && response.inside_polygon && response.location_id && response.subject_report_id) {
          this.$set(this.formDataCopy, 'city_district', response.location_id)
          this.$set(this.formDataCopy, 'road_administration', response.subject_report_id)
        }
        this.searchResults = []
      }
    }
  }
}

</script>

<style scoped>
.invalid-date-text-color >>> .v-text-field__slot input {
  color: red
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.uploaded-image {
  position: relative;
  width: 10rem;
  height: 10rem;
  margin: 0 1rem 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative; /* Make the container position relative */
}

.delete-button {
  position: absolute;
  top: -0.5rem;
  right: -0.2rem;
  background-color: lightgray;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  border-radius: 50%;
}

.pdf-info {
  display: flex;
  align-items: center;
  margin: 0 1rem 0.5rem 0;
  background-color: #D8D9DA;
  border-radius: 10px;
  padding: 0.4rem;
  border-bottom: 0.1rem solid black;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.pdf-info:hover {
  box-shadow: 0 4px 8px #5c5656;
}

.delete-pdf-icon {
  background: transparent;
  position: absolute;
  top: 0.3rem;
  right: 1.3rem;
  font-size: 2rem;
  color: red;
}

.delete-pdf-icon:hover {
  background: transparent;
  box-shadow: 0 4px 8px red;
  transition: box-shadow 0.3s ease;
}
</style>
